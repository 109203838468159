import React from 'react';

import { IHeading } from 'src/models/content.interfaces';
import { StyledComponent } from 'styled-components';
import { HeadingStyle, IHeadingStyle } from './Heading.style';

export const Heading = ({ colour, heading, variant = 'large', className, tag }: IHeading) => {
  let HeadingElement: StyledComponent<any, any, IHeadingStyle, never> = HeadingStyle;
  if (tag != null) {
    HeadingElement = HeadingStyle.withComponent(tag);
  }

  return (
    <HeadingElement
      data-id={`heading--${variant}`}
      variant={variant}
      colour={colour}
      className={className}
    >
      {heading}
    </HeadingElement>
  );
};
