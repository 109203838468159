import React from 'react';

import { ThemeProvider } from 'styled-components';
import { interaction } from '@origin-digital/reporting-client';

import { ILink } from 'src/models/content.interfaces';

import { COLOURS } from '../../../consts/style';
import { kebabCase, createRipple, getTargetAttribute } from '../../../utils/utils';
import { AccessibleText, StyledLink } from './Link.styles';

const ThemeMap = {
  default: {
    primaryColor: COLOURS.BRAND.PRIMARY,
    hoverColor: 'rgb(198, 0, 0)',
    outlinedHoverColor: 'rgba(236, 0, 0, 0.08)',
    contrastingColor: 'white',
  },
  yellow: {
    primaryColor: COLOURS.BACKGROUNDS.YELLOW,
    hoverColor: COLOURS.BACKGROUNDS.YELLOW,
    contrastingColor: COLOURS.NEUTRALS.DARK,
  },
  inverse: {
    primaryColor: 'rgb(255, 255, 255)',
    hoverColor: 'rgba(255, 255, 255, 80)',
    contrastingColor: COLOURS.BRAND.PRIMARY,
  },
};

export const ATag: React.FC<ILink> = ({
  accessibleLabel,
  url,
  children,
  newTab,
  trackingID,
  className,
  isOptiTestMAR530,
}) => {
  const onClickCapture = () => {
    interaction('click', {
      componentType: 'link',
      id: 'link',
      label: typeof children === 'string' ? kebabCase(children) : '',
    });
  };

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    createRipple(e);
  };

  return (
    <a
      data-id={trackingID}
      onClick={handleOnClick}
      onClickCapture={isOptiTestMAR530 ? undefined : onClickCapture}
      href={url}
      className={className}
      {...getTargetAttribute(newTab)}
    >
      {accessibleLabel ? (
        <>
          <span aria-hidden="true">{children}</span>
          <AccessibleText>{accessibleLabel}</AccessibleText>
        </>
      ) : (
        <>{children}</>
      )}
    </a>
  );
};

export const Link: React.FC<ILink> = (props) => {
  const { type = 'default', variant = 'default', theme = 'default' } = props;
  const selectTheme = ThemeMap[theme];
  const themeOptions = {
    linkType: type,
    linkVariant: variant,
  };

  const { className, ...aProps } = props;

  return (
    <ThemeProvider theme={{ ...selectTheme, ...themeOptions }}>
      <StyledLink className={className}>
        <ATag {...aProps} />
      </StyledLink>
    </ThemeProvider>
  );
};
