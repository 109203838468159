import React from 'react';

import { IconInfo as Info } from '@origin-digital/ods-icons';

import {
  ChevronRight,
  Check,
  CreditCard,
  DualFuel,
  Electricity,
  FuelPump,
  Gas,
  Internet,
  Help,
  Lpg,
  Move,
  Offer,
  Profile,
  Solar,
  Spike,
  SpikeOutline,
} from './Icons';

// @README

// how to manage the icons
// [Edit March 2023: Pandora's box is now closed ... import via ods-icons]

type IconProps = {
  iconName: string;
  width?: string;
  height?: string;
  color?: string;
};

export const Icon = (props: IconProps) => {
  const { iconName, ...rest } = props;

  const components = {
    ChevronRight,
    Check,
    CreditCard,
    DualFuel,
    Electricity,
    FuelPump,
    Gas,
    Help,
    Info,
    Internet,
    Lpg,
    Move,
    Offer,
    Profile,
    Solar,
    Spike,
    SpikeOutline,
  };

  const SelectIcon = components[iconName];
  if (typeof SelectIcon === 'undefined') return null;
  return <SelectIcon data-id={`${iconName.toLowerCase()}-icon`} {...rest} />;
};
