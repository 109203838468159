import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &:not(.offscreen) {
    #eLkq9hW8mtw2_ts {
      animation: eLkq9hW8mtw2_ts__ts 1500ms linear 1 normal forwards;
    }
    @keyframes eLkq9hW8mtw2_ts__ts {
      0% {
        transform: translate(8.2px, 25.4px) rotate(-45deg) scale(1, 0.05);
      }
      6.666667% {
        transform: translate(8.2px, 25.4px) rotate(-45deg) scale(1, 0.05);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      26.666667% {
        transform: translate(8.2px, 25.4px) rotate(-45deg) scale(1, 1);
      }
      100% {
        transform: translate(8.2px, 25.4px) rotate(-45deg) scale(1, 1);
      }
    }
    #eLkq9hW8mtw2 {
      animation: eLkq9hW8mtw2_c_o 1500ms linear 1 normal forwards;
    }
    @keyframes eLkq9hW8mtw2_c_o {
      0% {
        opacity: 0;
      }
      6.666667% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    #eLkq9hW8mtw3_ts {
      animation: eLkq9hW8mtw3_ts__ts 1500ms linear 1 normal forwards;
    }
    @keyframes eLkq9hW8mtw3_ts__ts {
      0% {
        transform: translate(18.4px, 35px) rotate(-135deg) scale(1, 0.1);
      }
      26.666667% {
        transform: translate(18.4px, 35px) rotate(-135deg) scale(1, 0.1);
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }
      66.666667% {
        transform: translate(18.4px, 35px) rotate(-135deg) scale(1, 1.95);
      }
      100% {
        transform: translate(18.4px, 35px) rotate(-135deg) scale(1, 1.95);
      }
    }
    #eLkq9hW8mtw3 {
      animation: eLkq9hW8mtw3_c_o 1500ms linear 1 normal forwards;
    }
    @keyframes eLkq9hW8mtw3_c_o {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      26.666667% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const TickAnimated = (props: any) => (
  <StyledSvg
    id="eLkq9hW8mtw1"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <g id="eLkq9hW8mtw2_ts" transform="translate(8.2,25.4) rotate(-45) scale(1,0.05)">
      <rect
        id="eLkq9hW8mtw2"
        width="4"
        height="16"
        rx="0"
        ry="0"
        transform="translate(-2.000027,0)"
        opacity="0"
        fill="currentColor"
        stroke="none"
        strokeWidth="0"
      />
    </g>
    <g id="eLkq9hW8mtw3_ts" transform="translate(18.4,35) rotate(-135) scale(1,0.098416)">
      <rect
        id="eLkq9hW8mtw3"
        width="4"
        height="16.434656"
        rx="0"
        ry="0"
        transform="translate(-1.999999,-0.000005)"
        opacity="0"
        fill="currentColor"
        stroke="none"
        strokeWidth="0"
      />
    </g>{' '}
  </StyledSvg>
);
