import * as React from 'react';
import styled from 'styled-components';

import { BREAK_POINTS } from '../../consts/style';

export const maxWidths = {
  SM: '540px',
  MD: '720px',
  LG: '960px',
  XL: '1140px',
};

interface ISectionProps extends Partial<SectionInnerProps> {
  children: React.ReactNode;
  bgColor?: string;
  padding?: boolean;
  paddingTop?: boolean;
}

type SectionOuterProps = {
  padding: boolean;
  paddingTop: boolean;
};

const SectionOuter = styled.div`
  ${(p: SectionOuterProps) =>
    p.padding &&
    `
  padding: ${p.paddingTop ? '64' : '0'}px  0 64px;
  overflow: hidden;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    padding:  ${p.paddingTop ? '94' : '0'}px 0 94px;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.XL}) {
    padding: ${p.paddingTop ? '96' : '0'}px 0 96px;
  }


  &.hidden-if-empty:empty {
    display: none;
  }

  `}
`;
type Variant = 'default' | 'full_width' | 'hide_gutter_xs';

type SectionInnerProps = {
  variant?: Variant;
};

export const SectionSidePadding = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;

  ${({ variant = 'default' }: SectionInnerProps) => {
    let out = '';

    if (variant === 'full_width') out += 'max-width: 100%;\n';
    else
      out += `
          @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
            padding: 0 16px;
            max-width: ${maxWidths.SM};
          }
          @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
            max-width: ${maxWidths.MD};
          }
          @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
            max-width: ${maxWidths.LG};
          }
          @media screen and (min-width: ${BREAK_POINTS.Min.XL}) {
            max-width: ${maxWidths.XL};
          }\n`;

    if (variant === 'default') out += 'padding: 0 16px;\n';

    return out;
  }}
`;

export const Section = ({
  bgColor = 'white',
  padding = true,
  paddingTop = true,
  variant = 'default',
  children,
}: ISectionProps) => {
  return (
    <SectionOuter
      data-id="section"
      padding={padding}
      paddingTop={paddingTop}
      style={{ backgroundColor: bgColor }}
    >
      <SectionSidePadding variant={variant}>{children}</SectionSidePadding>
    </SectionOuter>
  );
};

export const SectionOuterOnly = ({
  bgColor = 'white',
  padding = true,
  paddingTop = true,
  children,
}: ISectionProps) => (
  <SectionOuter
    data-id="section"
    padding={padding}
    paddingTop={paddingTop}
    style={{ backgroundColor: bgColor }}
    className="hidden-if-empty"
  >
    {children}
  </SectionOuter>
);
