import * as React from 'react';

import { interaction } from '@origin-digital/reporting-client';
import { QUICKLINKS_PREFIX } from '../../consts/tracking';

import { Icon } from '../Common/Icon';
import { IQuickLink } from '../../models/content.interfaces';
import { kebabCase, getTargetAttribute } from '../../utils/utils';
import { ChevronWrapper, IconWrapper, LabelWrapper, ListItem } from './QuickLink.styles';

export const QuickLink = ({ url, label, iconName, newTab }: IQuickLink) => {
  const onClickCapture = () => {
    interaction('click', {
      componentType: 'link',
      id: 'quicklinks',
      label: kebabCase(label),
    });
  };

  return (
    <ListItem>
      <a
        data-id={`${QUICKLINKS_PREFIX}-${kebabCase(label)}`}
        onClickCapture={onClickCapture}
        href={url}
        {...getTargetAttribute(newTab)}
      >
        <LabelWrapper>
          <IconWrapper>
            <Icon iconName={iconName} />
          </IconWrapper>
          <span>{label}</span>
        </LabelWrapper>
        <ChevronWrapper>
          <Icon iconName="ChevronRight" />
        </ChevronWrapper>
      </a>
    </ListItem>
  );
};
