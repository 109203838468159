import React from 'react';

import { ConditionalWrapper } from '../Common/ConditionalWrapper';
import { PaddingWrapper } from './Offers.styles';

interface IOfferImage {
  dataId: string;
  imageSrc: string;
  imageAltText: string;
  imagePadding: boolean;
}
export const OfferImage = ({ dataId, imageSrc, imageAltText, imagePadding }: IOfferImage) => {
  return (
    <ConditionalWrapper
      condition={imagePadding}
      wrapper={(children) => (
        <PaddingWrapper data-id="offer-padding--image">{children}</PaddingWrapper>
      )}
    >
      <img data-id={dataId} src={imageSrc} alt={imageAltText} />
    </ConditionalWrapper>
  );
};
