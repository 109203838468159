import React from 'react';

import styled from 'styled-components';
import { DualAnimated } from './DualAnimated';
import { InternetAnimated } from './InternetAnimated';
import { LinkAnimated } from './LinkAnimated';
import { LpgAnimated } from './LpgAnimated';
import { LockAnimated } from './LockAnimated';
import { SolarAnimated } from './SolarAnimated';
import { SwapAnimated } from './SwapAnimated';
import { TickAnimated } from './TickAnimated';

type IconProps = {
  iconName: string;
  className: string;
  width?: string;
  height?: string;
  color?: string;
  fadeOutTime?: number;
  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;
};
const components = Object.entries({
  DualAnimated,
  InternetAnimated,
  LinkAnimated,
  LockAnimated,
  LpgAnimated,
  SolarAnimated,
  SwapAnimated,
  TickAnimated,
}).reduce<Record<string, (props: any) => JSX.Element>>((acc, [key, Component]) => {
  return {
    ...acc,
    [key]: styled(Component)`
      opacity: 0;
      transition: opacity 100ms ease-in-out;

      &.transitioningOut {
        transition: opacity ${(props: { fadeOutTime: number }) => props.fadeOutTime || '300'}ms
          ease-in-out;
        opacity: 0 !important;
      }

      &:not(.offscreen) {
        opacity: 1;
      }
    `,
  };
}, {});

export const IconAnimated = (props: IconProps) => {
  const { iconName, ...rest } = props;

  const SelectIcon = components[iconName];
  if (typeof SelectIcon === 'undefined') return null;
  return <SelectIcon data-id={`${iconName.toLowerCase()}-icon-animated`} {...rest} />;
};
