import React from 'react';
import { TAIL_PREFIX } from '../../consts/tracking';
import { LinkVariant } from '../../models/content.interfaces';
import { kebabCase } from '../../utils/utils';
import { Text } from '../Common/Text';

import { Lamp, LampMobile, LinkContainer, Content, Link, Heading } from './ReadyToSwitch.styles';

type Props = {
  sectionHeading: string;
  text: string;
  links: Array<{
    text: string;
    url: string;
    variant?: LinkVariant;
  }>;
};

export default ({ sectionHeading, text, links }: Props) => (
  <Content>
    <Lamp />
    <LampMobile />
    <Heading tag="h2" heading={sectionHeading} variant="medium" colour="orange" />
    <Text text={text} />
    <LinkContainer>
      {links.map((link) => (
        <Link
          trackingID={`${TAIL_PREFIX}-${kebabCase(link.text)}`}
          key={link.text}
          type="button"
          variant={link.variant}
          url={link.url}
        >
          {link.text}
        </Link>
      ))}
    </LinkContainer>
  </Content>
);
