import styled from 'styled-components';

import { COLOURS } from '../../../consts/style';

interface ITagWrapper {
  colour?: string;
}

// currently either yellow or blue
export const TagWrapper = styled.div`
  display: inline-block;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: ${(p: ITagWrapper) => (p.colour === 'yellow' ? COLOURS.NEUTRALS.DARK : 'white')};
  background-color: ${(p: ITagWrapper) =>
    p.colour === 'yellow' ? COLOURS.BACKGROUNDS.YELLOW : COLOURS.BACKGROUNDS.AQUA};
`;
