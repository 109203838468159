import React from 'react';

import { Skeleton } from '../Common/Skeleton/Skeleton';
import {
  LoadingImageContainer,
  LoadingContainer,
  LoadingContentContainer,
} from './BannerLoading.styles';

export const BannerLoading = () => {
  return (
    <LoadingContainer title="banner loading" data-id="page-content-banner--loading">
      <LoadingContentContainer>
        <noscript>
          <h1>Australian Award Winning Electricity, Gas and Internet Provider</h1>
        </noscript>
        <Skeleton variant="heading1" />
        <Skeleton variant="heading1" />
        <Skeleton variant="heading1" />
        <Skeleton variant="heading1" />
        <Skeleton marginTop={24} width={200} height={150} />
        <Skeleton marginTop={24} variant="text" />
      </LoadingContentContainer>
      <LoadingImageContainer>
        <div data-id="banner-loading-image--desktop">
          <Skeleton />
        </div>
      </LoadingImageContainer>
    </LoadingContainer>
  );
};
