import * as React from 'react';

import styled from 'styled-components';
import { IQuickLinks } from '../../models/content.interfaces';

import { List } from './QuickLinks.styles';
import { QuickLink } from './QuickLink';

const Container = styled.div`
  padding-top: 64px;
`;

export const QuickLinks = ({ items }: IQuickLinks) => {
  return (
    <Container>
      <List aria-label="quick-links" data-id="quick-links">
        {items && items.map((item) => <QuickLink {...item} key={item.label} />)}
      </List>
    </Container>
  );
};
