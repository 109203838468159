import styled from 'styled-components';

import { BREAK_POINTS, handleHeadingColour } from '../../../consts/style';
import { HeadingVariant } from '../../../models/content.interfaces';

export type IHeadingStyle = {
  children: React.ReactNode;
  colour?: string;
  variant: HeadingVariant;
};

export const SectionHeadingStyle = styled.h2`
  font-size: 18px;
  color: ${(props: { colour?: string }) => handleHeadingColour(props.colour)};

  font-weight: 700;
  margin: 0 0 24px;
  text-align: center;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    font-size: 20px;
    margin: 0 0 48px;
  }
`;

const variants = {
  xsmall: {
    fontSize: '18px',
    lineHeight: '24px',
    fontSizeLg: '20px',
    lineHeightLg: '28px',
  },
  small: {
    fontSize: '24px',
    lineHeight: '32px',
    fontSizeLg: '32px',
    lineHeightLg: '40px',
  },
  medium: {
    fontSize: '24px',
    lineHeight: '32px',
    fontSizeLg: '40px',
    lineHeightLg: '48px',
  },
  large: {
    fontSize: '32px',
    lineHeight: '40px',
    fontSizeLg: '56px',
    lineHeightLg: '64px',
  },
} as const;

export const HeadingStyle = styled.h3`
  font-size: ${(p: IHeadingStyle) => variants[p.variant].fontSize};
  line-height: ${(p: IHeadingStyle) => variants[p.variant].lineHeight};
  font-weight: 700;
  color: ${(props: IHeadingStyle) => handleHeadingColour(props.colour)};

  font-weight: 700;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    font-size: ${(p: IHeadingStyle) => variants[p.variant].fontSizeLg};
    line-height: ${(p: IHeadingStyle) => variants[p.variant].lineHeightLg};
  }

  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &.offscreen {
    opacity: 0;
  }
`;
