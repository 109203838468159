import React from 'react';

import { AppearUp } from '../transitions';
import { APPSTORE_PREFIX } from '../../consts/tracking';
import { ChevronRight } from '../Common/Icons';
import { getCloudinaryUrl, kebabCase } from '../../utils/utils';
import { Heading } from '../Common/Headings';
import { IApp, IImage } from '../../models/content.interfaces';
import { Link } from '../Common/Link';
import { Text } from '../Common/Text';
import { AppWrapper, ContentWrapper, ImageWrapper, PaddingWrapper } from './App.styles';

const ImageColumn = ({ image }: IImage) => {
  // using browser Browser-level image lazy-loading at this stage only images lazy-loaded
  // https://web.dev/browser-level-image-lazy-loading
  // https://caniuse.com/loading-lazy-attr

  // max image size with page is 554 - image resolution switching TBC with real images
  const defaultImageSrc = getCloudinaryUrl(image);
  const highResImageSrc = getCloudinaryUrl(image, true, 70, 1108);
  return (
    <ImageWrapper>
      <img
        srcSet={`${defaultImageSrc}, ${highResImageSrc} 2x`}
        src={highResImageSrc}
        alt="Example of Origin Energy App"
        loading="lazy"
      />
    </ImageWrapper>
  );
};

export const App = ({ heading, description, image, label, url }: IApp) => (
  <AppWrapper>
    <ImageColumn image={image} />

    <ContentWrapper>
      <AppearUp>
        <Heading tag="h2" colour="orange" heading={heading} variant="medium" />
      </AppearUp>
      <AppearUp>
        <PaddingWrapper>
          <Text colour="dark" variant="lead" text={description} />
        </PaddingWrapper>
      </AppearUp>
      <AppearUp>
        <Link trackingID={`${APPSTORE_PREFIX}-${kebabCase(label)}`} url={url} variant="icon">
          {label} <ChevronRight />
        </Link>
      </AppearUp>
    </ContentWrapper>
  </AppWrapper>
);
