import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &:not(.offscreen) {
    #eQHySpK1Fm52_to {
      animation: eQHySpK1Fm52_to__to 3000ms linear 1 normal forwards;
    }
    @keyframes eQHySpK1Fm52_to__to {
      0% {
        transform: translate(9px, 24px);
      }
      3.333333% {
        transform: translate(9px, 24px);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      16.666667% {
        transform: translate(13.5px, 24px);
      }
      20% {
        transform: translate(13px, 24px);
      }
      100% {
        transform: translate(13px, 24px);
      }
    }
    #eQHySpK1Fm53_to {
      animation: eQHySpK1Fm53_to__to 3000ms linear 1 normal forwards;
    }
    @keyframes eQHySpK1Fm53_to__to {
      0% {
        transform: translate(39px, 24px);
      }
      3.333333% {
        transform: translate(39px, 24px);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      16.666667% {
        transform: translate(34.5px, 24px);
      }
      20% {
        transform: translate(35px, 24px);
      }
      100% {
        transform: translate(35px, 24px);
      }
    }
  }
`;

export const LinkAnimated = (props: any) => (
  <StyledSvg
    id="eQHySpK1Fm51"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <g id="eQHySpK1Fm52_to" transform="translate(9,24)">
      <path
        id="eQHySpK1Fm52"
        d="M7.8,24C7.8,20.58,10.58,17.8,14,17.8L22,17.8L22,14L14,14C8.48,14,4,18.48,4,24C4,29.52,8.48,34,14,34L22,34L22,30.2L14,30.2C10.58,30.2,7.8,27.42,7.8,24Z"
        transform="translate(-13,-24)"
        fill="currentColor"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <g id="eQHySpK1Fm53_to" transform="translate(39,24)">
      <path
        id="eQHySpK1Fm53"
        d="M34,14L26,14L26,17.8L34,17.8C37.42,17.8,40.2,20.58,40.2,24C40.2,27.42,37.42,30.2,34,30.2L26,30.2L26,34L34,34C39.52,34,44,29.52,44,24C44,18.48,39.52,14,34,14Z"
        transform="translate(-35,-24)"
        fill="currentColor"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <polygon
      id="eQHySpK1Fm54"
      points="16,26 32,26 32,22 16,22"
      fill="currentColor"
      stroke="none"
      strokeWidth="1"
    />
  </StyledSvg>
);
