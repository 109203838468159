import styled from 'styled-components';

import { ITextStyled } from 'src/models/content.interfaces';
import { COLOURS, handleFontColour } from '../../../consts/style';

const variants = {
  small: {
    fontSize: '12px',
    lineHeight: '20px',
  },
  default: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  lead: {
    fontSize: '16px',
    lineHeight: '24px',
  },
} as const;

export const TextStyled = styled.div`
  color: ${(p: ITextStyled) => handleFontColour(p.colour)};
  line-height: ${(p: ITextStyled) => variants[p.variant].lineHeight};

  & p,
  & ul,
  & ol {
    font-size: ${(p: ITextStyled) => variants[p.variant].fontSize};
    margin: 0;
    color: ${(p: ITextStyled) => handleFontColour(p.colour)};

    & a {
      color: ${COLOURS.NEUTRALS.DEFAULT};
    }

    & a:hover,
    & a:focus {
      text-decoration: none;
    }
  }
`;
