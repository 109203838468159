import styled from 'styled-components';

import { BREAK_POINTS, COLOURS } from '../../consts/style';

export const ListItem = styled.li`
  & a {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    border: 1px solid ${COLOURS.NEUTRALS.BORDERS};
    border-radius: 16px;
    overflow: hidden;
    box-sizing: border-box;
    min-height: 232px;
    // Design authorised cheat - look away
    padding: 16px 10px 16px 16px;

    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      padding: 16px;
    }

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      min-height: 295px;
      padding: 24px;
    }

    @media (prefers-reduced-motion: no-preference) {
      transition: box-shadow 0.25s ease;
    }
  }

  & a:hover,
  a:focus {
    box-shadow: rgb(0 0 0 / 8%) 0 0 16px 0;
  }

  & h3,
  & h4 {
    /* allow \n to create a new line */
    white-space: pre-wrap;

    margin: 0;
    font-weight: 700;
    line-height: 1;
    color: ${COLOURS.BRAND.PRIMARY_ORANGE};
  }

  & h3 {
    font-size: 24px;
    line-height: 32px;
    min-height: 72px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      font-size: 32px;
      line-height: 40px;
      min-height: 96px;
    }
  }

  & h4 {
    font-size: 14px;
    line-height: 24px;
    display: inline;
    font-weight: 500;
    color: ${COLOURS.BRAND.PRIMARY};
  }
`;

export const IconWrapper = styled.span`
  font-size: 48px;
  line-height: 48px;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    font-size: 64px;
    line-height: 64px;
  }

  color: ${COLOURS.BRAND.SECONDARY};
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 96px;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    min-height: 125px;
  }
`;
