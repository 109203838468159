import * as React from 'react';

function SvgInternet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8.553l2 2c4.97-4.97 13.03-4.97 18 0l2-2c-6.07-6.07-15.92-6.07-22 0zm8 8l3 3 3-3a4.237 4.237 0 00-6 0zm-2-2l-2-2c3.87-3.86 10.14-3.86 14 0l-2 2a7.074 7.074 0 00-10 0z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgInternet;
