import styled from 'styled-components';

import { BREAK_POINTS, COLOURS } from '../../consts/style';

export const RewardBenefitsList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  & li {
    margin-bottom: 48px;
  }
  & li:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    & li {
      margin-bottom: 64px;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    margin-bottom: 0;
  }

  & > img {
    width: 100%;
    height: auto;
    max-width: 350px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      max-width: 432px;
    }
  }
`;

export const RewardBenefitsItemWrapper = styled.li`
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    ${ImageWrapper} {
      text-align: left;
    }

    &.rewards-row--reversed {
      flex-direction: row-reverse;
    }

    &.rewards-row--reversed ${ImageWrapper} {
      text-align: right;
    }

    & > div {
      flex-grow: 1;
      width: 50%;
    }
  }
`;

export const TextColumnWrapper = styled.div`
  & h3 {
    margin: 12px 0 24px 0;
  }
  & p {
    margin-bottom: 24px;
  }
`;

export const IconWrapper = styled.div`
  & > svg,
  & > img {
    color: ${COLOURS.BRAND.SECONDARY};
    height: 48px;
    width: 48px;
  }
`;
