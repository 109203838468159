import * as React from 'react';

function SvgLpg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1h8v2h-1v2a3 3 0 013 3v11a2 2 0 01-2 2v2H8v-2a2 2 0 01-2-2V8a3 3 0 013-3V3H8V1zm3 4h2V4a1 1 0 00-.883-.993L12 3l-.117.007a1 1 0 00-.876.876L11 4v1zm5 3v11H8V8a1 1 0 011-1h6a1 1 0 011 1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLpg;
