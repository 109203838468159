import { IContentCardHeroBanner } from '@origin-digital/event-dispatcher';

// That apostophe you’re always looking for is here: ’

export const APPSTORE_URL = 'https://m.origin.com.au/fo2UErMINgb';

export const APP = {
  heading: 'An app built for bill-savvy folk, just like you',
  description: '- Make one-tap payments \n- Manage your services \n- Access offers & rewards',
  image: 'v1645506708/homepage/app-image_v2.png',
  label: 'Download the Origin App',
  url: APPSTORE_URL,
};

export const BANNER: IContentCardHeroBanner = {
  title: 'The energy provider with award-winning internet',
  body: 'Switch your electricity plan in minutes. Unlock a discount when you bundle both energy and internet.',
  location: 'homePage-heroBanner',
  experienceId: 'home-banner-default',
  image: {
    cloudinaryPath: 'v1690944734/personalisation/internet_hero-1-1_canstar-award_desktop_2023.png',
    altText: 'Award-winning internet',
  },
  imageMobile: {
    cloudinaryPath: 'v1690953332/personalisation/internet_hero_canstar-award_mobile_2023.png',
  },
  ctaContained: {
    label: 'Energy plans',
    action: '/electricity-gas/plans.html',
  },
  ctaOutlined: {
    label: 'Internet plans',
    action: '/internet/plans/',
  },
  hasPostcode: false,
  tag: '',
  terms: 'Winner! 2023 Canstar Blue Award for the best **Bundled Utilities Provider**',
  type: 'heroBanner',
};

export const OFFERS = {
  sectionHeading: 'Explore our latest offers on energy, solar & internet',
  items: [
    {
      category: 'SOLAR',
      description: '',
      tag: {
        text: '',
        colour: 'yellow',
      },
      heading: 'Discover great deals on solar',
      image: 'v1645136375/homepage/Offers/solar_promo_16x9.png',
      imageAltText: 'Get a free custom quote',
      imageBackground: false,
      imagePadding: false,
      imagePositionBottom: false,
      label: 'Get a free custom quote',
      accessibleLabel: '',
      url: '/solar/quote/',
      terms: '',
      theme: 'orange',
      isCentred: false,
    },
    {
      category: '',
      description: 'Reliable, no lock-in plans with more bang for your buck',
      tag: {
        text: 'INTERNET',
        colour: 'yellow',
      },
      heading: 'Award-winning internet',
      image: 'v1645567424/homepage/Offers/internet_promo_16x9_v2.png',
      imageAltText: 'Award-winning internet',
      imageBackground: false,
      imagePadding: false,
      imagePositionBottom: false,
      label: 'View internet plans',
      accessibleLabel: '',
      url: '/internet/plans/',
      terms: '',
      theme: 'white',
      isCentred: true,
    },
    {
      category: 'LPG bottled gas',
      description:
        'Get real-time updates so you can track your LPG bottled gas delivery or exchange.',
      tag: {
        text: '',
        colour: 'yellow',
      },
      heading: 'Door to door delivery',
      image: 'v1645506928/homepage/Offers/lpg_promo_16x9_v2.png',
      imageAltText: '',
      imageBackground: false,
      imagePadding: false,
      imagePositionBottom: false,
      label: 'Get a price',
      accessibleLabel: 'View LPG bottle gas offer',
      url: '/lpg/offers',
      terms: '',
      theme: 'light-yellow',
      isCentred: true,
    },
    {
      category: 'VIRTUAL POWER PLANT',
      description: 'Join an intelligent network of connected solar batteries with Origin Loop.',
      tag: {
        text: '',
        colour: 'yellow',
      },
      heading: 'Discover the new energy grid',
      image: 'v1645136375/homepage/Offers/loop_promo_16x9.png',
      imageAltText: '',
      imageBackground: false,
      imagePadding: false,
      imagePositionBottom: false,
      label: 'Learn more',
      accessibleLabel: "Learn more about Origin's energy grid",
      url: '/solar/panels-batteries/virtual-power-plant/',
      terms: '',
      theme: 'yellow',
      isCentred: true,
    },
    {
      category: 'ENERGY',
      description:
        "Life's full of the unexpected. Get an energy plan backed by Allianz Global Assistance.",
      tag: {
        text: '',
        colour: 'yellow',
      },
      heading: 'Energy + 24 hour support',
      image: 'v1645510136/homepage/Offers/homeassist_promo_16x9.png',
      imageAltText: '',
      imageBackground: false,
      imagePadding: false,
      imagePositionBottom: false,
      label: 'View plan',
      accessibleLabel: 'View emergency assist energy plan',
      url: '/for-home/electricity-and-gas/plans/home-support/',
      terms: '',
      theme: 'light-yellow',
      isCentred: true,
    },
  ],
};

export const PRODUCT_QUICK_LINKS = {
  sectionHeading: 'What services can we help you with today?',
  items: [
    {
      url: '/electricity-gas/plans.html',
      label: 'Compare plans',
      iconName: 'DualAnimated',
      heading: 'Electricity \n& gas',
      newTab: false,
    },
    {
      url: '/internet/plans/',
      label: 'See our plans',
      iconName: 'InternetAnimated',
      heading: 'Internet \nplans',
      newTab: false,
    },
    {
      url: '/solar/',
      label: 'Explore solutions',
      iconName: 'SolarAnimated',
      heading: 'Solar & batteries',
      newTab: false,
    },
    {
      url: '/lpg/',
      label: 'Get a price',
      iconName: 'LpgAnimated',
      heading: 'LPG \nbottles',
      newTab: false,
    },
  ],
};
export const QUICK_LINKS = {
  items: [
    {
      url: '/moving/',
      label: 'Move house',
      iconName: 'Move',
      newTab: false,
    },
    {
      url: '/pay',
      label: 'Make a payment',
      iconName: 'CreditCard',
      newTab: false,
    },
    {
      url: '/billing-payments',
      label: 'Billing & payments',
      iconName: 'Info',
      newTab: false,
    },
    {
      url: '/help-support',
      label: 'Help & support',
      iconName: 'Help',
      newTab: false,
    },
  ],
};

export const READY_TO_SWITCH = {
  sectionHeading: 'Ready to switch?',
  text: 'Make the hassle-free switch for great rates on your electricity, gas & internet.',
  links: [
    {
      text: 'Energy plans',
      url: '/electricity-gas/plans.html',
    },
    { text: 'Internet plans', url: '/internet/plans/' },
  ],
};

export const REWARDS_BENEFITS = {
  sectionHeading: 'Rewards & perks to boost your savings',
  items: [
    {
      description:
        'Boost your savings by collecting bonus sign-up points on the [Origin Everyday Rewards plan](/for-home/electricity-and-gas/plans/origin-everyday-rewards.html). Then collect ongoing points to save on a future shop. T&Cs&nbsp;apply.',
      heading: 'Collect Everyday Rewards points',
      image: 'v1669180118/homepage/Rewards/rewards_1-1_edr-incl-logo_01.png',
      label: 'Sign up today',
      accessibleLabel: '',
      url: '/for-home/electricity-and-gas/plans/origin-everyday-rewards.html',
    },
    {
      description:
        'Get fuel for less with Origin. You’ll save up to 12¢ a litre by combining with Woolworths and EG offers.\n\nSimply load the Origin app on your phone and present the offer at an [EG location](https://eg-australia.com/eg-store-locator/) to receive a discount on your fuel. \n[T&Cs apply](/terms-and-conditions/origin-fuel-offer-terms-and-conditions/).',
      heading: 'Get everyday savings on fuel',
      image: 'v1683519347/fuel_hero_eg-photo_v2.png',
      label: 'Learn more',
      accessibleLabel: 'Learn more on how to save 12¢ a litre',
      url: '/for-home/campaign/origin-fuel-discount/',
    },
    {
      description:
        'Join our 70,000+ community in their bid to save energy during peak-demand times. You’ll reduce your bills and earn PayPal cash and gift cards. \n\n Spike is the fun way to be more energy conscious. By adjusting your energy use to off-peak times you could save money on your electricity bill.',
      heading: 'Get paid to save energy',
      image: 'v1656646967/spike_hero-1-1_reward-cards_lg.png',
      label: 'Check out Spike',
      accessibleLabel: '',
      url: '/spike/',
    },
    {
      description:
        'Unlock bundle savings by having both energy and internet with us. \n\n With unlimited data on all plans and all speeds, Australia-based customer care specialists, and the freedom to change or cancel anytime. T&Cs&nbsp;apply',
      heading: 'Unlock discounts',
      image: 'v1656646967/bundle_hero-1-1_energy-meets-internet_lg.png',
      label: 'Explore internet plans',
      accessibleLabel: '',
      url: '/internet/plans/',
    },
  ],
};

const TERMS_TEXT =
  '\\* **No lock-in contract** \\n' +
  'Origin’s residential energy plans do not have a minimum lock-in contract period or exit fees.';

export const TERMS = {
  heading: 'Important information',
  headingBold: true,
  text: TERMS_TEXT,
};

export const TRUST_PILOT = {
  sectionHeading: 'How customers rate their experience with us',
};
