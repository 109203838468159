import React from 'react';

import { AppearUp } from '../transitions';
import { getCloudinaryUrl } from '../../utils/utils';
import { IRewardsBenefitsItem, IImage } from '../../models/content.interfaces';
import { TextColumn } from './TextColumn';

import { ImageWrapper, RewardBenefitsItemWrapper } from './RewardBenefits.styles';

const ImageColumn = ({ image, heading }: IImage) => {
  // using browser Browser-level image lazy-loading at this stage only images lazy-loaded
  // https://web.dev/browser-level-image-lazy-loading
  // https://caniuse.com/loading-lazy-attr

  // max image size with page is 432 - image resolution switching TBC with real images
  const defaultImageSrc = getCloudinaryUrl(image, true, 75, 432);
  const highResImageSrc = getCloudinaryUrl(image, true, 70, 900);
  return (
    <ImageWrapper>
      <img
        srcSet={`${defaultImageSrc}, ${highResImageSrc} 2x`}
        src={highResImageSrc}
        alt={heading}
        loading="lazy"
      />
    </ImageWrapper>
  );
};

export const RewardBenefitsItem = (props: IRewardsBenefitsItem) => {
  const { flipLayout, heading, image } = props;
  const className = flipLayout ? 'rewards-row' : 'rewards-row--reversed';

  return (
    <RewardBenefitsItemWrapper className={className}>
      <ImageColumn heading={heading} image={image} />
      <AppearUp>
        <TextColumn {...props} />
      </AppearUp>
    </RewardBenefitsItemWrapper>
  );
};
