import styled, { css } from 'styled-components';

import { BREAK_POINTS, COLOURS, TickList, TickItem } from '../../consts/style';

const list = css`
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 16px;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    gap: 32px;
  }
`;

const item = css`
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  width: 253px;

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    width: calc(50% - 8px);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    width: calc(33.3333% - 8px);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.XL}) {
    width: ${(1108 - 32 * 2) / 3}px;
  }
`;

export const List = styled.ul`
  ${list}
`;

export const Item = styled.li`
  ${item}
`;

export const OffersWrapper = styled.div`
  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    margin: 0 auto;
  }

  // adjust Swiper controls for only offers
  // always visible on no-mobile (not just on hover)

  & .slider-swiper {
    overflow: visible;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      overflow: hidden;
    }
  }

  // offers only resizing
  @media screen and (max-width: ${BREAK_POINTS.Max.MD}) {
    & .swiper[data-id='swiper--with-scrollbar'] .swiper-scrollbar {
      left: 16px;
      width: calc(100% - 32px);
    }
  }

  #swiper-prevBtn-offers,
  #swiper-nextBtn-offers {
    margin-top: -${25 + 32}px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      opacity: 1;
    }
  }

  #swiper-prevBtn-offers {
    left: 12px;
  }
  #swiper-nextBtn-offers {
    right: 12px;
  }

  // swiper no-js fix
  & .swiper[data-id='swiper--with-scrollbar']:not(.swiper-initialized) .swiper-wrapper {
    & > .swiper-slide {
      margin-right: 32px;
      max-width: 350px;
    }
  }
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-sizing: border-box;
  border-radius: 16px;
  overflow: hidden;
  height: 100%;

  //spacing
  [data-id^='tag--'] {
    margin: 0 auto 16px;
  }

  & [data-id^='offer-image'] {
    width: 100%;
    margin: 0 0 16px;

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      margin-bottom: 24px;
    }
  }

  // centred
  &.layout--centred [data-id='heading--small'],
  &.layout--centred p,
  &.layout--centred footer {
    text-align: center;
  }

  // themes
  ${(props) =>
    props.theme.backgroundColour === 'white' &&
    `
    border: 1px solid ${COLOURS.NEUTRALS.BORDERS};
    `}

  background-color: ${(props) => props.theme.backgroundColour};

  ${(props) =>
    props.theme.themeName === 'orange' &&
    `
    & [data-id="text--default"] p {
      font-size: 20px;
      line-height: 24px;
      color: white;
      font-weight: 600;
    } 
  `}

  & ul {
    ${TickList}
    font-size: 14px;
  }
  & li {
    ${TickItem}
  }
`;

export const HeaderWrapper = styled.header`
  padding: 24px 0 0;
  text-align: center;

  & div > h3 {
    text-align: left;
    margin: 0;
  }
`;
export const BIWrapper = styled.div`
  position: relative;
  padding-top: 0;

  &:before {
    display: block;
    content: '';
    width: 100%;
    margin-top: -24px;
    padding-top: 100%;

    background-size: cover;
    background-image: url('${(props) => props.theme.imageSrc}');
  }

  > div {
    position: absolute;
    top: 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const FooterWrapper = styled.footer`
  padding: 0 0 24px;
`;

export const Category = styled.p`
  display: block;
  margin: 0 auto 16px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  color: ${COLOURS.NEUTRALS.DARK};
`;

type PaddingWrapperProps = {
  paddingBottom?: boolean;
  paddingTop?: boolean;
};

export const PaddingWrapper = styled.div`
  padding: 0 16px;

  ${(p: PaddingWrapperProps) =>
    p.paddingTop &&
    `
    padding-top: 16px;
  `}

  ${(p: PaddingWrapperProps) =>
    p.paddingBottom &&
    `
    padding-bottom: 16px;
  `}

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;
