import React from 'react';

import { ITag } from '../../../models/content.interfaces';
import { TagWrapper } from './Tag.style';

export const Tag = ({ colour = 'yellow', text }: ITag) => {
  if (text === '') {
    return null;
  }
  return (
    <TagWrapper data-id={`tag--${colour}`} colour={colour}>
      {text}
    </TagWrapper>
  );
};
