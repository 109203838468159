import React from 'react';

import styled from 'styled-components';

type Props = {
  children: JSX.Element;
};

const Container = styled.div`
  animation: slidein 400ms ease-in-out, fadein 400ms ease-out;

  @keyframes slidein {
    from {
      transform: translateY(-30px);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export default ({ children }: Props) => <Container>{children}</Container>;
