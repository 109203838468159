import * as React from 'react';

import { ISkeleton } from 'src/models/content.interfaces';
import { SkeletonRoot } from './Skeleton.style';

export const Skeleton = ({
  height,
  style,
  variant = 'default',
  width,
  marginTop = 0,
}: ISkeleton) => (
  <SkeletonRoot
    data-id={`variant--${variant}`}
    variant={variant}
    style={{
      marginTop,
      width,
      height,
      ...style,
    }}
  />
);
