import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &.hover {
    #ethEqRCXrkT2 {
      animation: ethEqRCXrkT2_f_o 1000ms linear 2 normal forwards;
    }
    @keyframes ethEqRCXrkT2_f_o {
      0% {
        fill-opacity: 1;
      }
      10% {
        fill-opacity: 0;
      }
      50% {
        fill-opacity: 0;
      }
      60% {
        fill-opacity: 1;
      }
      100% {
        fill-opacity: 1;
      }
    }
    #ethEqRCXrkT3 {
      animation: ethEqRCXrkT3_f_o 1000ms linear 2 normal forwards;
    }
    @keyframes ethEqRCXrkT3_f_o {
      0% {
        fill-opacity: 1;
      }
      10% {
        fill-opacity: 1;
      }
      20% {
        fill-opacity: 0;
      }
      60% {
        fill-opacity: 0;
      }
      70% {
        fill-opacity: 1;
      }
      100% {
        fill-opacity: 1;
      }
    }
    #ethEqRCXrkT4 {
      animation: ethEqRCXrkT4_c_o 1000ms linear 2 normal forwards;
    }
    @keyframes ethEqRCXrkT4_c_o {
      0% {
        opacity: 1;
      }
      20% {
        opacity: 1;
      }
      30% {
        opacity: 0;
      }
      70% {
        opacity: 0;
      }
      80% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const InternetAnimated = (props: any) => (
  <StyledSvg
    id="ethEqRCXrkT1"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <path
      id="ethEqRCXrkT2"
      d="M18,34L24,40L30,34C26.7,30.7,21.3,30.7,18,34Z"
      clipRule="evenodd"
      fill="rgb(255,180,50)"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    />
    <path
      id="ethEqRCXrkT3"
      d="M14,30L10,26C17.7,18.3,30.3,18.3,38,26L34,30C28.5,24.5,19.5,24.5,14,30Z"
      clipRule="evenodd"
      fill="rgb(255,180,50)"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    />
    <path
      id="ethEqRCXrkT4"
      d="M2,18L6,22C15.9,12.1,32.1,12.1,42,22L46,18C33.9,5.9,14.2,5.9,2,18Z"
      transform="matrix(1 0 0 1 0 -0.173348)"
      clipRule="evenodd"
      fill="rgb(255,180,50)"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    />
  </StyledSvg>
);
