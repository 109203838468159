import * as React from 'react';

function SvgOffer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.094 6.88L57.094 30.88C58.054 31.84 58.6673 33.2 58.6673 34.6667C58.6673 36.1333 58.0807 37.4667 57.094 38.4267L38.4273 57.0933C37.4673 58.08 36.134 58.6667 34.6673 58.6667C33.2007 58.6667 31.8673 58.08 30.9073 57.12L6.90732 33.12C5.92065 32.1333 5.33398 30.8 5.33398 29.3333V10.6667C5.33398 7.73333 7.73398 5.33333 10.6673 5.33333H29.334C30.8007 5.33333 32.134 5.92 33.094 6.88ZM10.6673 14.6667C10.6673 16.88 12.454 18.6667 14.6673 18.6667C16.8807 18.6667 18.6673 16.88 18.6673 14.6667C18.6673 12.4533 16.8807 10.6667 14.6673 10.6667C12.454 10.6667 10.6673 12.4533 10.6673 14.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgOffer;
