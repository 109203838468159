import React from 'react';

import { IRewardsBenefits } from '../../models/content.interfaces';
import { SectionHeading } from '../Common/Headings';
import { RewardBenefitsItem } from './RewardsBenefitsItem';
import { RewardBenefitsList } from './RewardBenefits.styles';

export const RewardsBenefits = ({ items, sectionHeading }: IRewardsBenefits) => {
  return (
    <div data-id="rewards-and-benefits">
      <SectionHeading heading={sectionHeading} />
      <RewardBenefitsList aria-label="rewards-and-benfits-list">
        {items &&
          items.map((item, i) => {
            const flipLayout = i % 2 === 1;
            const newProps = { ...item, flipLayout };
            return <RewardBenefitsItem key={item.heading} {...newProps} />;
          })}
      </RewardBenefitsList>
    </div>
  );
};
