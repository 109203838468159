import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &.hover {
    #eWKJhGvn1Oi2_tr {
      animation: eWKJhGvn1Oi2_tr__tr 1000ms linear 1 normal forwards;
    }
    @keyframes eWKJhGvn1Oi2_tr__tr {
      0% {
        transform: translate(32px, 32px) rotate(0deg);
      }
      100% {
        transform: translate(32px, 32px) rotate(90deg);
      }
    }
  }
`;
export const SolarAnimated = (props: any) => (
  <StyledSvg
    id="eWKJhGvn1Oi1"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <g id="eWKJhGvn1Oi2_tr" transform="translate(32,32) rotate(0)">
      <g id="eWKJhGvn1Oi2" transform="translate(-32,-32)">
        <path
          id="eWKJhGvn1Oi3"
          d="M32,16C23.2,16,16,23.2,16,32C16,40.8,23.2,48,32,48C40.8,48,48,40.8,48,32C48,23.2,40.8,16,32,16ZM32,43C25.9,43,21,38.1,21,32C21,25.9,25.9,21,32,21C38.1,21,43,25.9,43,32C43,38.1,38.1,43,32,43Z"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi4"
          width="7.3"
          height="6"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 3 29)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi5"
          width="7.3"
          height="6"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 53.7 29)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi6"
          width="6"
          height="7.3"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 29 3)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi7"
          width="6"
          height="7.3"
          rx="0"
          ry="0"
          transform="matrix(1 0 0 1 29 53.7)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi8"
          width="7.3"
          height="6"
          rx="0"
          ry="0"
          transform="matrix(0.7072 -0.707 0.707 0.7072 45.258243 14.535231)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi9"
          width="7.3"
          height="6"
          rx="0"
          ry="0"
          transform="matrix(0.707 -0.7072 0.7072 0.707 9.337863 50.383709)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi10"
          width="6"
          height="7.3"
          rx="0"
          ry="0"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 45.222193 49.50054)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
        <rect
          id="eWKJhGvn1Oi11"
          width="6"
          height="7.3"
          rx="0"
          ry="0"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 9.372644 13.58003)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
    </g>{' '}
  </StyledSvg>
);
