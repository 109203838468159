import * as React from 'react';

function SvgSpikeOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.0007 5.33331C17.2731 5.33331 5.33398 17.2724 5.33398 32C5.33398 46.7276 17.2731 58.6666 32.0007 58.6666C46.7282 58.6666 58.6673 46.7276 58.6673 32C58.6673 24.9275 55.8578 18.1448 50.8568 13.1438C45.8559 8.14283 39.0731 5.33331 32.0007 5.33331ZM10.054 32C10.054 20.7157 18.6112 11.2706 29.8407 10.16V18.3466C25.8862 19.016 22.9268 22.3413 22.7207 26.3466C22.7719 30.3444 25.8328 33.6585 29.814 34.0266L33.6007 34.7733C35.494 35.1466 36.6407 36.24 36.6407 37.5733C36.6407 38.9066 35.174 40.56 32.614 40.56C29.8139 40.8031 27.3058 38.8316 26.8807 36.0533L21.734 37.3333C22.2245 41.5317 25.5356 44.8428 29.734 45.3333V53.7333C18.5873 52.576 10.103 43.2065 10.054 32ZM34.9607 53.76V45.3333C39.0257 44.814 42.1176 41.4287 42.2673 37.3333C42.1953 33.1079 38.9092 29.6365 34.694 29.3333L30.9873 28.64C29.0407 28.2666 28.3207 27.1733 28.3207 25.9733C28.6294 24.2094 30.2132 22.9584 32.0007 23.0666C34.3 22.8324 36.3964 24.396 36.8273 26.6666L41.814 25.2C41.2127 21.7041 38.4611 18.9739 34.9607 18.4V10.24C45.8097 11.7629 53.8795 21.0446 53.8795 32C53.8795 42.9554 45.8097 52.2371 34.9607 53.76Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSpikeOutline;
