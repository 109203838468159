import React from 'react';

import { IHeading } from 'src/models/content.interfaces';
import { SectionHeadingStyle } from './Heading.style';

export const SectionHeading = ({ colour, heading, className }: IHeading) => {
  return (
    <SectionHeadingStyle className={className} colour={colour}>
      {heading}
    </SectionHeadingStyle>
  );
};
