import { IAbTestValue, useAbTest } from '@origin-digital/optimizely';

import { CLOUDINARY_ENDPOINT, COMPARE_PLANS_PATH, TARGET_BLANK } from '../consts/config';
import { IMAGE_DEFAULTS } from '../consts/image';
import { ITargetAttributes, SkeletonVariant } from '../models/content.interfaces';

import manifest from '../../page.manifest.json';
import { config } from '../config';

// very loose UserAgent device checking to serve single App Store link
export const isAppleMobile = (userAgent: string) => {
  return /\b(iPad|iPhone)\b/i.test(userAgent);
};

export const isAndroid = (userAgent: string) => {
  return /\b(Android)\b/i.test(userAgent);
};

export const getComparePlanPath = (postcode: string) => {
  return `${COMPARE_PLANS_PATH}${postcode}`;
};

export const getCloudinaryUrl = (
  image: string,
  formatAuto: boolean = true,
  quality: number = IMAGE_DEFAULTS.QUALITY,
  width?: number,
): string => {
  return `${CLOUDINARY_ENDPOINT}/q_${quality}${formatAuto ? ',f_auto' : ''}${
    width ? `,c_scale,w_${width}` : ''
  }/${image}`;
};

export const getTargetAttribute = (newTab: boolean | undefined): ITargetAttributes => {
  return newTab ? TARGET_BLANK : {};
};

export const hexToRGB = (hex: string, alpha: number) => {
  if (hex.length === 4) {
    return hex;
  }
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha / 100})`;
};

export const getHeightObj = (size: 'SM' | 'MD', variant: SkeletonVariant) => {
  const heightObj = () => {
    switch (variant) {
      case 'text':
        return { SM: 'auto', MD: 'auto' };
      case 'heading1':
        return { SM: '36px', MD: '56px' };
      case 'heading2':
        return { SM: '24px', MD: '32px' };
      default:
        return { SM: '1.2em', MD: '1.2em' };
    }
  };
  return heightObj()[size];
};

// Pretend MIU ripple
export const createRipple = (
  e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
) => {
  const el = e.currentTarget;
  const circle = document.createElement('span');
  const diameter = Math.max(el.clientWidth, el.clientHeight);
  const radius = diameter / 2;

  const viewportOffset = el.getBoundingClientRect();
  const top = viewportOffset.top;
  const left = viewportOffset.left;

  circle.style.width = `${diameter}px`;
  circle.style.height = `${diameter}px`;
  circle.style.left = `${e.clientX - left - radius}px`;
  circle.style.top = `${e.clientY - top - radius}px`;
  circle.classList.add('ripple');

  const ripple = el.getElementsByClassName('ripple')[0];

  if (ripple) {
    ripple.remove();
  }

  el.appendChild(circle);
};

// Lodash replacements

// Note this is "good enough" formatting and not true lodash kebabcasing formatting
export const kebabCase = (string: string) => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const get = (obj: any, string: string, defaultValue: any = undefined) => {
  const parts = string.split(/[\][.]/).filter((x) => x);
  if (obj) {
    let attempt = obj;
    for (const part of parts) {
      if (attempt[part] === undefined && defaultValue) return defaultValue;
      attempt = attempt[part];
    }
    return attempt;
  }
};

// 😇 flashing inactive tab title
export const updateTitleOnVisibilityChange = () => {
  const originalTitle = manifest.page.config.title;
  let timeoutId: ReturnType<typeof setTimeout>;

  function animateTitle() {
    document.title = originalTitle;
    timeoutId = setTimeout(() => {
      document.title = 'Bundle & save 🎉';
      timeoutId = setTimeout(animateTitle, 1000);
    }, 4000);
  }

  function clearTitleAnimation() {
    clearTimeout(timeoutId);
    document.title = originalTitle;
  }

  document.addEventListener('visibilitychange', () => {
    if (document.hidden) {
      animateTitle();
    } else {
      clearTitleAnimation();
    }
  });
};

export const getTestContent = (
  activeExperiment: IAbTestValue | undefined,
  controlContent: any,
  variationContent: any,
  variationValue: string,
) => {
  if (!activeExperiment) {
    return controlContent;
  }

  if (activeExperiment?.loading) {
    return undefined; // its loading make sure its clear to avoid FOC
  }
  // if the returned experiment variation name matches the expected variation return new content
  // else return control
  return activeExperiment?.variation === variationValue ? variationContent : controlContent;
};

export const useIsOptiTest = (experimentKey: string): boolean => {
  // tslint:disable-next-line:react-hooks-nesting
  const abTest = useAbTest(config.optimizelyExperiment?.[experimentKey]);
  return abTest?.variation === 'Exp B';
};
