import styled from 'styled-components';

import { BREAK_POINTS } from '../../consts/style';

export const LoadingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  width: 100%;
  box-sizing: border-box;

  z-index: 0;
  border: 0;

  background-color: white;

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    gap: 48px;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
  }
`;

export const LoadingContentContainer = styled.div`
  width: 100%;
  margin-top: 48px;
  & [data-id='variant--heading1']:nth-child(1),
  & [data-id='variant--heading1']:nth-child(2) {
    display: none;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    margin-top: 0;
    & [data-id='variant--heading1']:nth-child(1),
    & [data-id='variant--heading1']:nth-child(2) {
      display: block;
    }
    padding-top: 48px;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    & [data-id='variant--heading1']:nth-child(1) {
      display: none;
    }
  }
`;

export const LoadingImageContainer = styled.div`
  order: -1;

  &,
  & span {
    width: 300px;
    height: 289px;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    order: 1;
    &,
    & span {
      width: 370px;
      height: 370px;
    }
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    margin-left: auto;
    width: auto;

    &,
    & span {
      width: 495px;
      height: 495px;
    }
  }
`;
