import { BREAK_POINTS, COLOURS } from '../../../consts/style';
import { hexToRGB } from '../../../utils/utils';
import { LinkButtonShared } from '../Shared.styles';
import styled from 'styled-components';

export const StyledLink = styled.div`
  & a {
    ${LinkButtonShared}
    border-radius: ${(props) =>
      props.theme.linkVariant === 'small' || props.theme.linkVariant === 'icon' ? '50px' : '100px'};
    font-size: ${(props) =>
      props.theme.linkVariant === 'small' || props.theme.linkVariant === 'icon' ? '14px' : '16px'};
    font-weight: ${(props) =>
      props.theme.linkVariant === 'small' || props.theme.linkVariant === 'icon' ? '700' : '600'};

    // overwrite for tal adding weight on spans
    & > span {
      font-weight: ${(props) =>
        props.theme.linkVariant === 'small' || props.theme.linkVariant === 'icon' ? '700' : '600'};
    }

    ${({ theme }) =>
      theme.linkType === 'button'
        ? `
        ${
          theme.linkVariant === 'outlined'
            ? `
            color: ${theme.primaryColor};
            border: ${theme.primaryColor} 1px solid;
            background-color: transparent;
            `
            : `
            color: ${theme.contrastingColor};
            background-color: ${theme.primaryColor};
            `
        }
        
        &:hover,
        &:focus,
        &:active {
          ${
            theme.linkVariant === 'outlined'
              ? `
            background-color: ${theme.outlinedHoverColor};
            `
              : `
            background-color: ${theme.hoverColor};
            `
          }
        }
        
        @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
          padding: 16px 32px;
        }
      `
        : `
        color: ${COLOURS.BRAND.PRIMARY};
        background-color: transparent;
        padding: 16px;

        &:hover,
        &:focus,
        &:active {
          background-color: ${hexToRGB(COLOURS.BRAND.PRIMARY, 8)};
        }

        ${
          theme.linkVariant === 'icon' &&
          `
        margin: 0 0 24px -16px;
        padding-right: 32px;

        & svg {
          position: absolute;
          font-size: 24px;
          top: 11px;
          right: 8px;
          transform: translate(-2px, 0);
          transition: transform 0.25s ease-out;
        }
    
        &:hover svg,
        &:focus svg {
          transform: translate(2px, 0);
        }
        `
        }
    `}
  }
`;

export const AccessibleText = styled.span`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
`;
