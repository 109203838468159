import styled from 'styled-components';

import { BREAK_POINTS } from '../../consts/style';

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
