import * as React from 'react';

function SvgDualFuel(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M3 18.75a.77.77 0 00.75.75h3a.77.77 0 00.75-.75V18H3zM5.25 4.5a5.25 5.25 0 00-3 9.56V16a.81.81 0 00.75.8h4.5a.81.81 0 00.75-.8v-1.94a5.25 5.25 0 00-3-9.56zm2 8.17l-.6.42V15h-2.8v-1.91l-.6-.43a3.49 3.49 0 114.87-.87 3.59 3.59 0 01-.87.87zM21.57 9.6l-4.18-4.1-4.17 4.1a5.74 5.74 0 000 8.21 6 6 0 004.08 1.69h.14a5.78 5.78 0 004.11-9.9zm-4.78 8a2.7 2.7 0 010-2.94l.6-.77.54.77a2.74 2.74 0 010 2.94 1.33 1.33 0 01-.29.29h-.57a1.28 1.28 0 01-.28-.29zm2.19 0c1.21-1.56.61-2.56-.06-3.77l-1.53-2.28-1.59 2.25c-.66 1.2-1.12 2.1-.08 3.74a4.07 4.07 0 01-2.48-3.62v-.12a4.21 4.21 0 011.33-3l2.82-3 2.83 3a4.13 4.13 0 011.32 2.94v.12A4 4 0 0119 17.57z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDualFuel;
