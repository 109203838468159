import React, { useState } from 'react';

import { interaction } from '@origin-digital/reporting-client';
import { PRODUCTQUICKLINKS_PREFIX } from '../../consts/tracking';

import { IconAnimated } from '../Common/IconsAnimated';
import { IProductQuickLink } from '../../models/content.interfaces';
import { kebabCase, getTargetAttribute } from '../../utils/utils';
import { IconWrapper, HeadingWrapper, ListItem } from './ProductQuickLink.styles';

export const ProductQuickLink = ({ url, label, iconName, newTab, heading }: IProductQuickLink) => {
  const [hovered, setHovered] = useState(false);
  const [running, setRunning] = useState(false);

  const onClickCapture = () => {
    interaction('click', {
      componentType: 'link',
      id: 'product-quicklinks',
      label: kebabCase(label),
    });
  };

  const handleOnMouseEnter = () => {
    if (!running) {
      setHovered(true);
      setRunning(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (!running) {
      setHovered(false);
    }
  };

  const handleOnAnimationEnd = () => {
    if (hovered && running) {
      setRunning(false);
    }
  };

  const handleOnAnimationStart = () => {
    setRunning(true);
  };

  return (
    <ListItem>
      <a
        data-id={`${PRODUCTQUICKLINKS_PREFIX}-${kebabCase(heading)}`}
        onClickCapture={onClickCapture}
        href={url}
        {...getTargetAttribute(newTab)}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        <IconWrapper>
          <IconAnimated
            iconName={iconName}
            onAnimationEnd={handleOnAnimationEnd}
            onAnimationStart={handleOnAnimationStart}
            className={running ? 'hover' : ''}
          />
        </IconWrapper>
        <HeadingWrapper>
          <h3>{heading}</h3>
          <h4>{label}</h4>
        </HeadingWrapper>
      </a>
    </ListItem>
  );
};
