import styled from 'styled-components';

import { BREAK_POINTS, TickItem, TickList } from '../../consts/style';

export const AppWrapper = styled.div`
  text-align: center;
  margin: 0 auto;

  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    width: 80%;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    width: 100%;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 32px;

    &.rewards-row--reversed {
      flex-direction: row-reverse;
    }

    & > div {
      flex-grow: 1;
      width: 50%;
    }
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    text-align: left;
  }
`;

export const ImageWrapper = styled.div`
  & > img {
    width: 100%;
    height: auto;
    margin-bottom: 32px;

    @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
      max-width: 400px;
    }

    @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
      max-width: 100%;
      margin-bottom: 0;
    }
  }
`;

export const ContentWrapper = styled.div`
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    max-width: 430px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    max-width: 100%;
  }

  & h2 {
    margin-top: 0;
  }

  & ul {
    ${TickList}
    // zero bottom margin and let paddingWrapper take care of it
    margin-bottom: 0;
  }
  & li {
    ${TickItem}
  }
`;

export const PaddingWrapper = styled.div`
  margin-bottom: 16px;

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    margin-bottom: 24px;
  }
`;
