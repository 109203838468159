/**
 * This file will configure various resources for your application.
 * - Logging to Sumologic (More configuration required - see below)
 * - Analytics tracking through Adobe Target Events
 * - Daxi (The Origin Digital http request library)
 *
 * Anything else required before your app is loaded can be included in this
 * file.
 */

import SumoLogger from 'sumo-logger';
import { configAnalytics, configSumo } from '@origin-digital/reporting-client';
import { appName, config } from './config';

const envAppName = `${appName}-${config.env}`;

configAnalytics({
  appName,
  debugToConsole: config.analyticsDebugToConsole,
});

configSumo({
  SumoLogger,
  appName: envAppName,
  consoleLogLevel: config.logLevelConsole as LogLevel,
  endpoint: `https://collectors.au.sumologic.com/receiver/v1/${config.sumoCollectorKey}`,
  sendOnErrors: config.sumoSendOnErrors,
  sourceEnv: config.env as EnvironmentNames,
  sumoLogLevel: config.logLevelSumo as LogLevel,
});

if (__DEV__) {
  require('./mocks/eventDispatcherMocks');
  require('./mocks/optimizelyMocks');
}
