import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &.hover {
    #eRJOzd6iEaf2_to {
      animation: eRJOzd6iEaf2_to__to 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf2_to__to {
      0% {
        transform: translate(13.98px, 32.1px);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      9.5% {
        transform: translate(31.98px, 34.1px);
      }
      66.666667% {
        transform: translate(31.98px, 34.1px);
      }
      76.2% {
        transform: translate(13.98px, 32.1px);
      }
      80.95% {
        transform: translate(13.98px, 32.1px);
      }
      100% {
        transform: translate(13.98px, 32.1px);
      }
    }
    #eRJOzd6iEaf2_ts {
      animation: eRJOzd6iEaf2_ts__ts 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf2_ts__ts {
      0% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      9.5% {
        transform: scale(1.15, 1.15);
      }
      14.285714% {
        transform: scale(1.1, 1.1);
      }
      66.666667% {
        transform: scale(1.1, 1.1);
      }
      76.2% {
        transform: scale(0.95, 0.95);
      }
      80.95% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }
    #eRJOzd6iEaf3_ts {
      animation: eRJOzd6iEaf3_ts__ts 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf3_ts__ts {
      0% {
        transform: translate(46.2px, 52px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      9.5% {
        transform: translate(46.2px, 52px) scale(0.9, 0.9);
      }
      71.428571% {
        transform: translate(46.2px, 52px) scale(0.9, 0.9);
      }
      80.95% {
        transform: translate(46.2px, 52px) scale(1.02, 1.02);
      }
      85.71% {
        transform: translate(46.2px, 52px) scale(1, 1);
      }
      100% {
        transform: translate(46.2px, 52px) scale(1, 1);
      }
    }
    #eRJOzd6iEaf3 {
      animation: eRJOzd6iEaf3_f_o 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf3_f_o {
      0% {
        fill-opacity: 1;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      9.5% {
        fill-opacity: 0;
      }
      71.428571% {
        fill-opacity: 0;
      }
      76.2% {
        fill-opacity: 1;
      }
      100% {
        fill-opacity: 1;
      }
    }
    #eRJOzd6iEaf4 {
      animation: eRJOzd6iEaf4_s_do 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf4_s_do {
      0% {
        stroke-dashoffset: 24;
      }
      23.8% {
        stroke-dashoffset: 24;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      38.1% {
        stroke-dashoffset: 16;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      52.4% {
        stroke-dashoffset: 8;
      }
      100% {
        stroke-dashoffset: 8;
      }
    }
    #eRJOzd6iEaf5 {
      animation: eRJOzd6iEaf5_s_do 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf5_s_do {
      0% {
        stroke-dashoffset: 24;
      }
      23.9% {
        stroke-dashoffset: 24;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      38.18% {
        stroke-dashoffset: 32;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      52.4% {
        stroke-dashoffset: 40;
      }
      100% {
        stroke-dashoffset: 40;
      }
    }
    #eRJOzd6iEaf6 {
      animation: eRJOzd6iEaf6_s_do 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf6_s_do {
      0% {
        stroke-dashoffset: 24.18;
      }
      23.8% {
        stroke-dashoffset: 24.18;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      38.1% {
        stroke-dashoffset: 32.24;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      52.4% {
        stroke-dashoffset: 40.3;
      }
      100% {
        stroke-dashoffset: 40.3;
      }
    }
    #eRJOzd6iEaf7 {
      animation: eRJOzd6iEaf7_s_do 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf7_s_do {
      0% {
        stroke-dashoffset: 24;
      }
      23.8% {
        stroke-dashoffset: 24;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      38.1% {
        stroke-dashoffset: 32;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      52.4% {
        stroke-dashoffset: 40;
      }
      100% {
        stroke-dashoffset: 40;
      }
    }
    #eRJOzd6iEaf8 {
      animation: eRJOzd6iEaf8_s_do 2100ms linear 1 normal forwards;
    }
    @keyframes eRJOzd6iEaf8_s_do {
      0% {
        stroke-dashoffset: 24.18;
      }
      23.8% {
        stroke-dashoffset: 24.18;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      38.1% {
        stroke-dashoffset: 32.24;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      52.4% {
        stroke-dashoffset: 40.3;
      }
      100% {
        stroke-dashoffset: 40.3;
      }
    }
  }
`;

export const DualAnimated = (props: any) => (
  <StyledSvg
    id="eRJOzd6iEaf1"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <g id="eRJOzd6iEaf2_to" transform="translate(13.979586,32.099998)">
      <g id="eRJOzd6iEaf2_ts" transform="scale(1,1)">
        <path
          id="eRJOzd6iEaf2"
          d="M19.7,48L19.7,50.1C19.7,51.4,18.9,52.2,17.8,52.2L9.8,52.2C8.8,52,8,51.2,8,50.1L8,48L19.7,48ZM13.9,12C18.7,12,23,14.1,25.4,18.1C29.9,24.5,28.3,33,21.9,37.6L21.9,42.7C21.9,43.8,21.1,44.8,20,44.8L8,44.8C6.9,44.8,6.1,43.7,6.1,42.7L6.1,37.4C2.1,34.7,0,30.4,0,25.9C0,18.1,6.4,12,13.9,12ZM19.5,18.4C15.2,15.5,9.4,16.5,6.4,20.8C3.4,25.1,4.5,30.9,8.8,33.9L10.4,35L10.4,40L17.6,40L17.6,34.9L19.2,33.8C20.3,33,21.1,32.2,21.9,31.1C24.8,27.2,23.7,21.3,19.5,18.4Z"
          transform="translate(-13.979586,-32.099998)"
          fill="rgb(255,180,50)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
    </g>
    <g id="eRJOzd6iEaf3_ts" transform="translate(46.214282,52) scale(1,1)">
      <path
        id="eRJOzd6iEaf3"
        d="M46.4,14.7L57.6,25.6C60.5,28.5,62.1,32.5,61.9,36.8C61.9,45.3,55,52,46.4,52L46.1,52C42.1,52,38.1,50.4,35.2,47.5L34.9,47.2C29,41.1,29,31.5,35.2,25.6L46.4,14.7ZM46.4,37.1L44.8,39.2C43.2,41.6,43.2,44.5,44.8,46.9C45.1,47.2,45.3,47.4,45.3,47.7L46.9,47.7L47.7,46.9C49.3,44.5,49.3,41.6,47.7,39.2L46.4,37.1ZM46.1,20.5L38.6,28.5C36.5,30.6,35.1,33.6,35.1,36.5L35.1,36.8C35.4,41.1,37.8,44.8,41.8,46.4C39.1,42.1,40.2,39.7,42.1,36.5L46.4,30.6L50.4,36.7C52.3,39.9,53.9,42.6,50.7,46.8C54.7,45.2,57.4,41.2,57.1,36.4L57.1,36.1C57.1,33.2,55.8,30.5,53.6,28.4L46.1,20.5Z"
        transform="translate(-46.087291,-52)"
        fill="rgb(255,180,50)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <line
      id="eRJOzd6iEaf4"
      x1="51"
      y1="28"
      x2="59"
      y2="28"
      fill="none"
      stroke="rgb(255,180,50)"
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeDashoffset="24"
      strokeDasharray="8"
    />
    <line
      id="eRJOzd6iEaf5"
      x1="5"
      y1="28"
      x2="13"
      y2="28"
      fill="none"
      stroke="rgb(255,180,50)"
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeDashoffset="24"
      strokeDasharray="8"
    />
    <line
      id="eRJOzd6iEaf6"
      x1="12.2"
      y1="8.2"
      x2="17.9"
      y2="13.9"
      fill="none"
      stroke="rgb(255,180,50)"
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeDashoffset="24.18"
      strokeDasharray="8.06"
    />
    <line
      id="eRJOzd6iEaf7"
      x1="32"
      y1="0"
      x2="32"
      y2="8"
      fill="none"
      stroke="rgb(255,180,50)"
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeDashoffset="24"
      strokeDasharray="8"
    />
    <line
      id="eRJOzd6iEaf8"
      x1="51.8"
      y1="8.2"
      x2="46.1"
      y2="13.9"
      fill="none"
      stroke="rgb(255,180,50)"
      strokeWidth="5"
      strokeMiterlimit="10"
      strokeDashoffset="24.18"
      strokeDasharray="8.06"
    />{' '}
  </StyledSvg>
);
