import React from 'react';
import Markdown from 'markdown-to-jsx';

import { IText } from 'src/models/content.interfaces';

import { TextStyled } from './Text.styles';

export const Text = ({ colour, text, variant, className }: IText) => {
  const variantType = variant ? variant : 'default';
  return (
    <>
      {text
        .split('\\n')
        .filter(Boolean)
        .map((output, i) => {
          return (
            <TextStyled
              key={`sh-${i}`}
              data-id={`text--${variantType}`}
              colour={colour}
              variant={variantType}
              className={className}
            >
              <Markdown options={{ forceBlock: true }} children={output} />
            </TextStyled>
          );
        })}
    </>
  );
};
