import React from 'react';

import { TrustPilotWidget } from '@origin-digital/trustpilot-widget';

import { IOffers } from '../../models/content.interfaces';

import {
  APP,
  BANNER,
  OFFERS,
  PRODUCT_QUICK_LINKS,
  QUICK_LINKS,
  READY_TO_SWITCH,
  REWARDS_BENEFITS,
} from '../../consts/staticContent';
import { COLOURS } from '../../consts/style';
import { App } from '../App';
import { HeroBanner } from '../Banner/HeroBanner';
import { Offers } from '../Offers';
import { ProductQuickLinks } from '../ProductsQuickLinks';
import { QuickLinks } from '../QuickLinks';
import { RewardsBenefits } from '../RewardsBenefits';
import { Section, SectionOuterOnly, SectionSidePadding } from '../Common';
import ReadyToSwitch from '../ReadyToSwitch';
import { updateTitleOnVisibilityChange } from '../../utils/utils';

export const PageContent = () => {
  updateTitleOnVisibilityChange();

  return (
    <main>
      <Section paddingTop={false} variant="full_width">
        <HeroBanner {...BANNER} />
        <SectionSidePadding>
          <QuickLinks {...QUICK_LINKS} />
        </SectionSidePadding>
      </Section>
      <Section paddingTop={false}>
        <ProductQuickLinks {...PRODUCT_QUICK_LINKS} />
      </Section>
      <Section variant="full_width" paddingTop={false}>
        <Offers {...(OFFERS as IOffers)} />
      </Section>
      <Section paddingTop={false}>
        <RewardsBenefits {...REWARDS_BENEFITS} />
      </Section>
      <SectionOuterOnly bgColor={COLOURS.NEUTRALS.BACKGROUND_LIGHT}>
        <TrustPilotWidget backgroundColor="transparent" />
      </SectionOuterOnly>
      <Section>
        <App {...APP} />
      </Section>
      <Section bgColor={COLOURS.NEUTRALS.BACKGROUND_LIGHT}>
        <ReadyToSwitch {...READY_TO_SWITCH} />
      </Section>
    </main>
  );
};
