import * as React from 'react';

function SvgElectricity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C8.135 2 5 5.135 5 9a6.99 6.99 0 003 5.74V17c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2.26A6.99 6.99 0 0019 9c0-3.865-3.135-7-7-7zM9 21c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-1H9v1zm5-7.305l.855-.595A5.003 5.003 0 0017 9.005c0-2.755-2.245-5-5-5s-5 2.245-5 5c0 1.63.8 3.16 2.145 4.1L10 13.7V16h4v-2.305z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgElectricity;
