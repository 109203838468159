import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &:not(.offscreen) {
    #ecYeWgtn3KZ2_to {
      animation: ecYeWgtn3KZ2_to__to 2000ms linear 1 normal forwards;
    }
    @keyframes ecYeWgtn3KZ2_to__to {
      0% {
        transform: translate(30px, 24px);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      13.333333% {
        transform: translate(22.4px, 24px);
      }
      20% {
        transform: translate(24px, 24px);
      }
      100% {
        transform: translate(24px, 24px);
      }
    }
    #ecYeWgtn3KZ3_to {
      animation: ecYeWgtn3KZ3_to__to 2000ms linear 1 normal forwards;
    }
    @keyframes ecYeWgtn3KZ3_to__to {
      0% {
        transform: translate(18px, 24px);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      13.333333% {
        transform: translate(25.615px, 24px);
      }
      20% {
        transform: translate(24px, 24px);
      }
      100% {
        transform: translate(24px, 24px);
      }
    }
  }
`;

export const SwapAnimated = (props: any) => (
  <StyledSvg
    id="ecYeWgtn3KZ1"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <g id="ecYeWgtn3KZ2_to" transform="translate(30,24)">
      <path
        id="ecYeWgtn3KZ2"
        d="M29.98,26L29.98,20L42,20L42,16L29.98,16L29.98,10L22,18L29.98,26Z"
        transform="translate(-24,-24)"
        fill="rgb(255,180,50)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <g id="ecYeWgtn3KZ3_to" transform="translate(18,24)">
      <path
        id="ecYeWgtn3KZ3"
        d="M18.02,28L6,28L6,32L18.02,32L18.02,38L26,30L18.02,22L18.02,28Z"
        transform="translate(-24,-24)"
        fill="rgb(255,180,50)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
  </StyledSvg>
);
