import React, { useState, useEffect } from 'react';

import { HeroBannerWidget } from '@origin-digital/personalisation-widget';

import {
  appReadyDiagnostics,
  appDiagnostics,
  contentCards,
  fetchGeolocation,
  IContentCardHeroBanner,
  IContentCardsResult,
  isContentCardHeroBanner,
} from '@origin-digital/event-dispatcher';

import { BannerLoading } from './BannerLoading';
import { DEFAULT_POSTCODE, LOCATIONS } from '../../consts/config';
import { SectionSidePadding } from '../Common';

export const HeroBanner = (props: IContentCardHeroBanner) => {
  const [loading, setLoading] = useState(true);
  const [bannerContent, setBannerContent] = useState<IContentCardHeroBanner>({
    ...props,
  } as IContentCardHeroBanner);
  const [postcodePlaceholder, setPostcodePlaceholder] = useState<string>(DEFAULT_POSTCODE);

  useEffect(() => {
    const time = performance.now();
    contentCards({
      location: LOCATIONS.heroBanner,
      limit: 1,
    })
      .then(({ cards }: IContentCardsResult) => {
        const [content] = cards;
        if (content && isContentCardHeroBanner(content)) {
          setBannerContent(content);
        }
        setLoading(false);
        appReadyDiagnostics();
        appDiagnostics({
          name: 'brazeContentCardDuration',
          duration: performance.now() - time,
        });
      })
      .catch((reason) => {
        appDiagnostics({
          name: 'brazeContentCardFailure',
          duration: performance.now() - time,
          metadata: {
            errorMessage: reason?.message,
          },
        });
      });
  }, []);

  // Geo Postcode
  useEffect(() => {
    if (bannerContent.hasPostcode && postcodePlaceholder === DEFAULT_POSTCODE) {
      const setPostcode = async () => {
        const geolocation = await fetchGeolocation({});
        setPostcodePlaceholder(geolocation?.postcode || DEFAULT_POSTCODE);
      };

      setPostcode();
    }
  }, [bannerContent]);

  return loading ? (
    <SectionSidePadding>
      <BannerLoading />
    </SectionSidePadding>
  ) : (
    <HeroBannerWidget {...{ ...bannerContent, postcodePlaceholder }} />
  );
};
