import styled from 'styled-components';

import { BREAK_POINTS, COLOURS } from '../../../consts/style';

const transitionTime = '0.15s';

export const ArrowWrapper = styled.div`
  opacity: 0;

  transition: opacity ${transitionTime} ease-in-out;

  z-index: 2;

  top: 50%;
  margin-top: -25px;

  position: absolute;
  width: 50px;
  height: 50px;
  ${(props: { right?: boolean }) => (props.right ? 'right: 0;' : '')}
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  // arrow visibility
  display: none;
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    display: block;
  }

  // turn off arrows when disabled
  &.swiper-button-disabled {
    display: none;
  }
`;

export const Container = styled.div`
  // Default trimmed down Swiper CSS
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-pointer-events {
    touch-action: pan-y;
  }
  .swiper-pointer-events.swiper-vertical {
    touch-action: pan-x;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .swiper-slide-invisible-blank {
    visibility: hidden;
  }
  //END Default Swiper code

  position: relative;

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    &:hover ${ArrowWrapper} {
      opacity: 1;

      transition: opacity ${transitionTime} ease-in-out;
    }
  }

  // with scrollbar
  & .swiper[data-id='swiper--with-scrollbar'] {
    position: relative;

    padding-bottom: 64px;

    .swiper-scrollbar {
      position: absolute;
      left: 0;
      bottom: 32px;
      height: 4px;
      width: 100%;
      background-color: ${COLOURS.NEUTRALS.BACKGROUND_DARK};
    }

    .swiper-scrollbar-drag {
      height: 4px;
      background-color: ${COLOURS.BACKGROUNDS.YELLOW};
    }
  }

  & {
    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      overflow: hidden;
    }
  }
  & .swiper-slide {
    height: auto;
  }

  & .swiper-slide:last-child {
    padding-right: 0;
  }

  @media only screen and (min-width: 1024px) {
    & .swiper-slide:first-child {
      padding: 0;
    }
  }
`;
