/* istanbul ignore file : nothing to test in svg */
import React from 'react';

export default ({ className }: { className?: string }) => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <mask
      id="mask0_1329_55695"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="128"
      height="128"
    >
      <path
        d="M64 128C99.3462 128 128 99.3462 128 64C128 28.6538 99.3462 0 64 0C28.6538 0 0 28.6538 0 64C0 99.3462 28.6538 128 64 128Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1329_55695)">
      <path
        d="M64 128C99.3462 128 128 99.3462 128 64C128 28.6538 99.3462 0 64 0C28.6538 0 0 28.6538 0 64C0 99.3462 28.6538 128 64 128Z"
        fill="#F1F1F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2516 87.3802C70.2573 87.5662 69.298 87.9057 68.4081 88.3864L67.1428 89.4743L71.5918 92.4794L79.9047 93.4312L81.102 90.0862C76.8843 87.4074 74.5441 87.0539 71.2516 87.3802Z"
        fill="#ED9174"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.1324 112.475C73.4913 113.007 73.6843 113.634 73.687 114.276C73.6888 114.704 73.606 115.128 73.4434 115.524C73.2808 115.92 73.0416 116.28 72.7394 116.583C72.4373 116.887 72.0782 117.127 71.6828 117.291C71.2874 117.455 70.8635 117.54 70.4353 117.54C69.7928 117.54 69.1646 117.35 68.6302 116.993C68.0957 116.637 67.679 116.13 67.4324 115.537C67.1859 114.944 67.1207 114.291 67.2451 113.661C67.3695 113.031 67.6778 112.452 68.1312 111.997C68.5846 111.542 69.1628 111.231 69.7926 111.104C70.4225 110.977 71.0759 111.04 71.6703 111.284C72.2647 111.528 72.7735 111.942 73.1324 112.475ZM71.8406 113.374C72.0216 113.64 72.1196 113.954 72.1223 114.276C72.1241 114.492 72.0832 114.705 72.002 114.905C71.9207 115.104 71.8007 115.286 71.649 115.439C71.4972 115.592 71.3166 115.713 71.1176 115.796C70.9186 115.879 70.7052 115.922 70.4897 115.922C70.1673 115.922 69.8522 115.826 69.584 115.648C69.3159 115.469 69.1066 115.215 68.9826 114.917C68.8587 114.62 68.8256 114.293 68.8875 113.977C68.9494 113.66 69.1035 113.37 69.3305 113.141C69.5575 112.912 69.8471 112.756 70.1629 112.691C70.4787 112.627 70.8065 112.657 71.1051 112.779C71.4036 112.9 71.6596 113.107 71.8406 113.374Z"
        fill="#FFB432"
      />
      <path d="M69.3469 72.8442H71.5374V112.223H69.3469V72.8442Z" fill="#FFB432" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.9866 89.284C63.7145 90.2358 63.2655 91.3101 63.4015 92.3163V92.697C63.4357 93.0734 63.5845 93.4302 63.8277 93.7195C64.071 94.0088 64.397 94.2167 64.7621 94.3152C64.4194 94.477 64.1343 94.7394 63.9447 95.0674C63.7551 95.3954 63.67 95.7733 63.7009 96.1508V96.5316C63.7312 96.9092 63.8785 97.2679 64.1224 97.558C64.3662 97.848 64.6945 98.0548 65.0614 98.1497C64.7188 98.3116 64.4336 98.574 64.244 98.902C64.0544 99.2299 63.9694 99.6079 64.0002 99.9854V100.366C64.0451 100.852 64.2806 101.301 64.6553 101.614C65.0299 101.928 65.5134 102.08 66.0002 102.039H66.7621C66.5819 102.242 66.4513 102.483 66.3804 102.745C66.3096 103.007 66.3005 103.282 66.3539 103.548C66.4492 104.622 67.2791 105.316 68.9117 105.397C70.5444 105.479 73.2655 105.941 76.2315 106.118C78.5489 106.129 80.8507 105.738 83.0342 104.962C87.8817 104.493 92.7647 104.524 97.6056 105.057C100.327 105.506 118.476 107.546 131.837 107.954V90.5078C123.497 90.7253 113.633 91.5276 109.497 92.0715C97.9729 93.5537 87.7281 93.9888 84.2315 91.8948C80.7349 89.8007 79.6873 89.1752 76.8709 89.3792C76.3267 89.3792 73.3471 89.6919 71.4287 89.8687C69.5104 90.0455 68.5716 89.012 68.5036 88.2642L65.9866 89.284Z"
        fill="#FAC6B7"
      />
      <path d="M91.1157 90.4534H95.6735V110.197H91.1157V90.4534Z" fill="#0A5FD2" />
      <path d="M95.6735 90.4534H100.231V110.197H95.6735V90.4534Z" fill="white" />
      <path d="M100.231 90.4534H104.789V110.197H100.231V90.4534Z" fill="#0A5FD2" />
      <path d="M104.789 90.4534H109.347V110.197H104.789V90.4534Z" fill="white" />
      <path d="M109.347 90.4534H113.905V110.197H109.347V90.4534Z" fill="#0A5FD2" />
      <path d="M113.905 90.4534H118.462V110.197H113.905V90.4534Z" fill="white" />
      <path d="M118.463 90.4534H123.02V110.197H118.463V90.4534Z" fill="#0A5FD2" />
      <path d="M123.02 90.4534H127.578V110.197H123.02V90.4534Z" fill="white" />
      <path d="M127.578 90.4534H132V110.197H127.578V90.4534Z" fill="#0A5FD2" />
      <path d="M51.4015 48.5315H56.7756V128.241H51.4015V48.5315Z" fill="#747170" />
      <path d="M53.9321 48.5315H56.7756V128.241H53.9321V48.5315Z" fill="#D5D5D5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.6462 18.9429H38.4625L23.9999 82.4035H84.1088L69.6462 18.9429Z"
        fill="white"
      />
    </g>
  </svg>
);
