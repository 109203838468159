import * as React from 'react';

function SvgFuelPump(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.54 14.46L39.56 14.44L32.12 7L30 9.12L34.22 13.34C32.34 14.06 31 15.86 31 18C31 20.76 33.24 23 36 23C36.72 23 37.38 22.84 38 22.58V37C38 38.1 37.1 39 36 39C34.9 39 34 38.1 34 37V28C34 25.8 32.2 24 30 24H28V10C28 7.8 26.2 6 24 6H12C9.8 6 8 7.8 8 10V42H28V27H31V37C31 39.76 33.24 42 36 42C38.76 42 41 39.76 41 37V18C41 16.62 40.44 15.36 39.54 14.46ZM24 20H12V10H24V20ZM36 20C34.9 20 34 19.1 34 18C34 16.9 34.9 16 36 16C37.1 16 38 16.9 38 18C38 19.1 37.1 20 36 20Z"
        fill="#FFB432"
      />
    </svg>
  );
}

export default SvgFuelPump;
