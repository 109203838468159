export const appName = 'origin-homepage';

export const CLOUDINARY_ENDPOINT =
  'https://www.originenergy.com.au/cdn/images/originenergy/image/upload';

export const COMPARE_PLANS_PATH =
  '/electricity-gas/plans.html?planfuel=elec_gas&plansearchpostcode=';
export const COMPARE_PLANS = '/electricity-gas/plans.html';

export const TARGET_BLANK = {
  target: '_blank',
  rel: 'noopener',
};

export enum LOCATIONS {
  heroBanner = 'homePage-heroBanner',
  quickLinks = 'homePage-quicklinks', // future location
  productQuickLinks = 'homePage-productQuickLinks', // future location
  testAnalytics = 'homePage-testAnalytics',
  // ab tests
  abTestQuickLinks = 'homePage-abTest-quickLinks',
  abTestProductQuickLinks = 'homePage-abTest-productQuickLinks',
  abTestTitleSwitch = 'homePage-abTest-titleSwitch',
}

export const DEFAULT_POSTCODE = '2000';
