import React from 'react';

import { REWARDS_PREFIX } from '../../consts/tracking';

import { IRewardsBenefitsItem } from '../../models/content.interfaces';
import { kebabCase } from '../../utils/utils';
import { ChevronRight } from '../Common/Icons';
import { Heading } from '../Common/Headings';
import { Link } from '../Common/Link';
import { Text } from '../Common/Text';

import { TextColumnWrapper } from './RewardBenefits.styles';
import { AccessibleText } from '../Common/Link/Link.styles';

export const TextColumn = ({
  description,
  heading,
  label,
  accessibleLabel,
  url,
}: IRewardsBenefitsItem) => {
  return (
    <TextColumnWrapper>
      <Heading heading={heading} colour="orange" variant="medium" />

      <Text text={description} />
      {accessibleLabel ? (
        <Link trackingID={`${REWARDS_PREFIX}-${kebabCase(heading)}`} url={url} variant="icon">
          <span aria-hidden="true">{label}</span>
          <AccessibleText>{accessibleLabel}</AccessibleText>
          <ChevronRight />
        </Link>
      ) : (
        <Link trackingID={`${REWARDS_PREFIX}-${kebabCase(heading)}`} url={url} variant="icon">
          {label}
          <ChevronRight />
        </Link>
      )}
    </TextColumnWrapper>
  );
};
