import * as React from 'react';

function SvgSolar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1h2v3h-2V1zM4.96 3.5l1.8 1.79L5.34 6.7 3.55 4.91 4.96 3.5zM4 11H1v2h3v-2zm16.448-6.098L19.04 3.495l-1.789 1.789 1.407 1.408 1.79-1.79zM17.24 18.61l1.79 1.8L20.44 19l-1.8-1.79-1.4 1.4zM23 11h-3v2h3v-2zM12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-4 5.95c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4zM13 20v3h-2v-3h2zm-8.04.4l-1.41-1.41 1.79-1.8 1.41 1.41-1.79 1.8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSolar;
