interface ImageDefaults {
  readonly QUALITY: number;
  readonly WIDTHS: number[];
  readonly MAX_WIDTH: string;
  readonly SIZES: Sizes;
  readonly OPEN_GRAPH_IMAGE_WIDTH: number;
}

export interface Sizes {
  XS: string;
  SM: string;
  MD: string;
  LG: string;
}

export const IMAGE_DEFAULTS: ImageDefaults = {
  QUALITY: 70,
  WIDTHS: [212, 425, 850, 1700],
  MAX_WIDTH: '100vw',
  SIZES: {
    XS: '100vw',
    SM: '100vw',
    MD: '100vw',
    LG: '100vw',
  },
  OPEN_GRAPH_IMAGE_WIDTH: 600,
};
