import { css } from 'styled-components';

export const LinkButtonShared = css`
  /* include padding in the Button's total width (since the padding is part of the button style)  */
  box-sizing: border-box;

  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  transition: background 400ms;

  &:focus {
    outline: none;
  }

  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.35);
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;
