import { useState } from 'react';

export const useOnScreenState = (): [string, (isVisible: boolean) => void] => {
  const [onScreenState, setOnScreenState] = useState('');

  const onChangeHandler = (isVisible: boolean) => {
    // the strategy here is to use "offscreen" to to hide or reset items rather
    // than onscreen to show items. This is so that if for whatever reason
    // VisibilitySensor doesn't trigger, we show by default.
    const state = isVisible ? 'onscreen' : 'offscreen';
    setOnScreenState(state);
  };

  return [onScreenState, onChangeHandler];
};
