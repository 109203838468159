/* istanbul ignore file : nothing to test in svg */
import React from 'react';

export default ({ className }: { className?: string }) => (
  <svg
    width="118"
    height="264"
    viewBox="0 0 118 264"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_1223_55393)">
      <path d="M4.97985 259.271H60.7541V264.985H4.97985V259.271Z" fill="#747170" />
      <path d="M4.97985 259.271H60.7541V261.231H4.97985V259.271Z" fill="#9D9A9E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.6269 74.0183C50.5405 74.2229 49.4924 74.5963 48.5201 75.1251L47.1376 76.3217L51.9985 79.6273L61.0812 80.6743L62.3893 76.9948C57.7811 74.0482 55.2243 73.6593 51.6269 74.0183Z"
        fill="#ED9174"
      />
      <path d="M49.5457 58.0286H51.9391V101.345H49.5457V58.0286Z" fill="#FFC72C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8742 76.1123C43.3917 77.1593 42.9012 78.341 43.0498 79.4478V79.8666C43.0872 80.2806 43.2497 80.6731 43.5155 80.9913C43.7812 81.3096 44.1375 81.5382 44.5364 81.6466C44.162 81.8246 43.8505 82.1133 43.6433 82.4741C43.4361 82.8348 43.3432 83.2506 43.3769 83.6658V84.0846C43.41 84.5 43.571 84.8946 43.8374 85.2137C44.1039 85.5327 44.4625 85.7602 44.8634 85.8646C44.489 86.0427 44.1775 86.3313 43.9703 86.6921C43.7632 87.0528 43.6702 87.4686 43.7039 87.8839V88.3027C43.753 88.8375 44.0103 89.3311 44.4197 89.6757C44.829 90.0204 45.3573 90.1882 45.8891 90.1424H46.7215C46.5247 90.3656 46.3819 90.6317 46.3046 90.9198C46.2272 91.2079 46.2173 91.5101 46.2756 91.8027C46.3796 92.9844 47.2864 93.7472 49.0702 93.837C50.8541 93.9267 53.8271 94.4353 57.0677 94.6297C59.5997 94.6421 62.1146 94.2119 64.5003 93.3583C69.7966 92.8417 75.1318 92.8768 80.421 93.463C83.394 93.9566 103.224 96.2002 117.822 96.649V77.4585C108.709 77.6978 97.9322 78.5803 93.4132 79.1786C80.8223 80.809 69.6288 81.2876 65.8085 78.9841C61.9881 76.6807 60.8435 75.9926 57.7664 76.217C57.1718 76.217 53.9163 76.561 51.8203 76.7555C49.7243 76.9499 48.6986 75.8131 48.6243 74.9905L45.8742 76.1123Z"
        fill="#FAC6B7"
      />
      <path d="M73.3302 77.3987H78.31V99.117H73.3302V77.3987Z" fill="#0A5FD2" />
      <path d="M78.3099 77.3987H83.2898V99.117H78.3099V77.3987Z" fill="white" />
      <path d="M83.2897 77.3987H88.2695V99.117H83.2897V77.3987Z" fill="#0A5FD2" />
      <path d="M88.2694 77.3987H93.2493V99.117H88.2694V77.3987Z" fill="white" />
      <path d="M93.2496 77.3987H98.2294V99.117H93.2496V77.3987Z" fill="#0A5FD2" />
      <path d="M98.2293 77.3987H103.209V99.117H98.2293V77.3987Z" fill="white" />
      <path d="M103.209 77.3987H108.189V99.117H103.209V77.3987Z" fill="#0A5FD2" />
      <path d="M108.189 77.3987H113.169V99.117H108.189V77.3987Z" fill="white" />
      <path d="M113.169 77.3987H118V99.117H113.169V77.3987Z" fill="#0A5FD2" />
      <path d="M29.9386 31.2847H35.8104V258.968H29.9386V31.2847Z" fill="#747170" />
      <path d="M32.7035 31.2847H35.8104V258.968H32.7035V31.2847Z" fill="#EEEEEE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.8728 1H15.8017L0 70.8067H65.6745L49.8728 1Z"
        fill="#EEEEEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.6818 101.622C54.0739 102.208 54.2848 102.898 54.2877 103.604C54.2897 104.075 54.1993 104.541 54.0216 104.977C53.8439 105.412 53.5825 105.808 53.2524 106.142C52.9223 106.475 52.53 106.74 52.098 106.921C51.666 107.101 51.2028 107.194 50.735 107.194C50.0329 107.194 49.3466 106.985 48.7627 106.593C48.1788 106.201 47.7234 105.643 47.4541 104.991C47.1847 104.338 47.1135 103.62 47.2494 102.927C47.3853 102.234 47.7222 101.597 48.2176 101.097C48.7129 100.596 49.3446 100.255 50.0328 100.115C50.721 99.9753 51.4348 100.044 52.0843 100.312C52.7337 100.581 53.2896 101.036 53.6818 101.622ZM52.2704 102.611C52.4681 102.904 52.5752 103.25 52.5782 103.604C52.5801 103.841 52.5355 104.076 52.4467 104.296C52.3579 104.515 52.2268 104.715 52.061 104.883C51.8952 105.051 51.6979 105.185 51.4805 105.276C51.2631 105.367 51.0299 105.414 50.7944 105.414C50.4422 105.414 50.0979 105.309 49.8049 105.113C49.5119 104.916 49.2832 104.637 49.1478 104.309C49.0123 103.982 48.9762 103.622 49.0438 103.274C49.1114 102.927 49.2799 102.607 49.5278 102.355C49.7758 102.104 50.0923 101.931 50.4373 101.861C50.7824 101.79 51.1406 101.823 51.4668 101.957C51.793 102.09 52.0726 102.318 52.2704 102.611Z"
        fill="#FFC72C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1223_55393">
        <rect width="118" height="264" fill="white" transform="matrix(-1 0 0 1 118 0)" />
      </clipPath>
    </defs>
  </svg>
);
