import styled from 'styled-components';
import { BREAK_POINTS } from '../../consts/style';
import { Link as Link_ } from '../Common/Link';
import { Heading as Heading_ } from '../Common/Headings';
import Lamp_ from './Lamp';
import LampMobile_ from './LampMobile';

const imgHeight = '265px';

export const Heading = styled(Heading_)`
  margin: 0;
`;

export const Lamp = styled(Lamp_)`
  display: none;

  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    display: block;
    height: ${imgHeight};
    right: 0;
    position: absolute;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    left: 0;
    transform: scaleX(-1);
  }
`;

export const LampMobile = styled(LampMobile_)`
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    display: none;
  }
`;

export const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    /* all buttons to the left */
    grid-template-columns: auto 1fr;
    flex-direction: row;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    /* all buttons just take up their own size */
    grid-template-columns: auto auto;
  }
`;

export const Content = styled.div`
  display: grid;

  gap: 32px;

  // centre text at small and large breakpoints, but not at medium
  @media screen and (max-width: ${BREAK_POINTS.Max.SM}), (min-width: ${BREAK_POINTS.Min.LG}) {
    & ${LinkContainer} {
      justify-content: center;
    }
    text-align: center;
    margin: 0 auto;
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    // allow the last row to fill the rest of the height of the absolute
    // positioned image
    min-height: ${imgHeight};
    grid-template-rows: auto auto 1fr;

    max-width: 400px;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    max-width: 500px;
  }
  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    max-width: 650px;
  }
`;

export const Link = styled(Link_)`
  @media screen and (max-width: ${BREAK_POINTS.Max.SM}) {
    width: 100%;

    & a {
      width: 100%;
      padding: 16px 20px;
    }
  }
`;
