import * as React from 'react';

function SvgCreditCard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.5 4h-16c-1.11 0-1.99.89-1.99 2L2.5 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14h-16v-6h16v6zm0-10h-16V6h16v2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCreditCard;
