import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import styled from 'styled-components';

import { BREAK_POINTS } from '../../../consts/style';
import { useOnScreenState } from '../hooks/useOnScreenState';

type Props = {
  children: JSX.Element;
};

const Container = styled.div`
  opacity: 1;
  transform: translateY(0px);
  transition: transform 400ms ease-in-out, opacity 400ms ease-out;

  &.offscreen {
    opacity: 0;
    transform: translateY(10px);

    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      transform: translateY(30px);
    }
  }
`;

export default ({ children }: Props) => {
  const [onScreenState, onVisibilityChange] = useOnScreenState();

  return (
    <VisibilitySensor
      onChange={onVisibilityChange}
      partialVisibility={true}
      offset={{ bottom: 150 }}
    >
      <Container className={onScreenState}>{children}</Container>
    </VisibilitySensor>
  );
};
