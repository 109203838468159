import * as React from 'react';

import { OriginThemeProvider } from '@origin-digital/ods-core';
import { configAnalytics, navigation } from '@origin-digital/reporting-client';

import { PageContent } from './components/PageContent/PageContent';
import { appName } from './consts/config';

export class App extends React.Component {
  public componentDidMount() {
    configAnalytics({ appName });
    navigation('screen', {
      appName,
      currentUri: window.location.href,
      friendlyUri: 'originenergy',
    });
  }

  public render() {
    return (
      <OriginThemeProvider generatorClassNameOptions={{ seed: 'app' }}>
        <PageContent />
      </OriginThemeProvider>
    );
  }
}
