import styled from 'styled-components';

import { BREAK_POINTS } from '../../consts/style';

export const List = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;
  list-style-type: none;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    gap: 32px;
  }
`;
