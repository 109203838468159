import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  &.hover {
    #eEjkwlcXisH2_ts {
      animation: eEjkwlcXisH2_ts__ts 3000ms linear 1 normal forwards;
    }
    @keyframes eEjkwlcXisH2_ts__ts {
      0% {
        transform: translate(32px, 47.7px) scale(1, 0);
      }
      13.333333% {
        transform: translate(32px, 47.7px) scale(1, 0);
        animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
      30% {
        transform: translate(32px, 47.7px) scale(1, 1);
      }
      46.666667% {
        transform: translate(32px, 47.7px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }
      100% {
        transform: translate(32px, 47.7px) scale(1, 0);
      }
    }
    #eEjkwlcXisH4_to {
      animation: eEjkwlcXisH4_to__to 3000ms linear 1 normal forwards;
    }
    @keyframes eEjkwlcXisH4_to__to {
      0% {
        transform: translate(31.949999px, 8px);
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      }
      3.333333% {
        transform: translate(31.949999px, 13.3px);
      }
      30% {
        transform: translate(31.949999px, 13.3px);
        animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      }
      36.666667% {
        transform: translate(31.949999px, 8px);
      }
      40% {
        transform: translate(31.949999px, 9px);
      }
      43.333333% {
        transform: translate(31.949999px, 8px);
      }
      100% {
        transform: translate(31.949999px, 8px);
      }
    }
  }
`;

export const LpgAnimated = (props: any) => (
  <StyledSvg
    id="eEjkwlcXisH1"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <g id="eEjkwlcXisH2_ts" transform="translate(32,47.7) scale(1,0)">
      <path
        id="eEjkwlcXisH2"
        d="M39.7,47.7L24.3,47.7L24.3,22.7C24.3,22.1,24.7,21.7,25.3,21.7L38.6,21.7C39.2,21.7,39.6,22.1,39.6,22.7L39.6,47.7L39.7,47.7Z"
        transform="translate(-32,-47.7)"
        fill="rgb(255,180,50)"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <path
      id="eEjkwlcXisH3"
      d="M40,13.3C44.4,13.3,48,16.9,48,21.3L48,50.6C48,53.5,45.6,55.9,42.7,55.9L42.7,61.2L21.3,61.2L21.3,56C18.4,56,16,53.6,16,50.7L16,21.3C16,16.9,19.6,13.3,24,13.3L40,13.3ZM42.7,21.3L42.7,50.6L21.3,50.6L21.3,21.3C21.3,19.8,22.5,18.6,24,18.6L40,18.6C41.5,18.7,42.7,19.9,42.7,21.3Z"
      clipRule="evenodd"
      fill="rgb(255,180,50)"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
    />
    <g id="eEjkwlcXisH4_to" transform="translate(31.949999,8)">
      <path
        id="eEjkwlcXisH4"
        d="M34.6,13.3L34.6,10.6C34.6,9.2,33.6,8.1,32.2,8L32,8L31.7,8C30.5,8.1,29.5,9.1,29.4,10.3L29.4,10.6L29.4,13.3L24,13.3L24,8L21.3,8L21.3,2.7L42.6,2.7L42.6,8L40,8L40,13.3L34.6,13.3Z"
        transform="translate(-31.949999,-8)"
        clipRule="evenodd"
        fill="rgb(255,180,50)"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
      />
    </g>
  </StyledSvg>
);
