import styled from 'styled-components';

import { BREAK_POINTS, COLOURS } from '../../consts/style';

export const IconWrapper = styled.span`
  font-size: 24px;
  line-height: 24px;
  color: ${COLOURS.BRAND.SECONDARY};
  margin-right: 8px;
`;

export const ChevronWrapper = styled.span`
  font-size: 24px;
  line-height: 24px;
  color: ${COLOURS.NEUTRALS.DEFAULT};

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    display: none;
  }
`;

export const LabelWrapper = styled.span`
   {
    display: flex;
    margin: 0;
    font-size: 14px;

    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      justify-content: center;
    }

    & > span {
      font-weight: 600;
    }
    & > * {
      align-self: center;
    }
  }
`;

export const ListItem = styled.li`
  border-radius: 8px;
  overflow: hidden;
  flex-grow: 1;

  @media screen and (min-width: ${BREAK_POINTS.Min.SM}) {
    flex: 1 1 calc(50% - 8px);
  }

  @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
    flex: 1 1 calc(25% - 8px);
  }

  & a {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    padding: 12px 12px 12px 16px;
    color: ${COLOURS.NEUTRALS.DARK};
    background-color: ${COLOURS.NEUTRALS.BACKGROUND_LIGHT};

    @media (prefers-reduced-motion: no-preference) {
      transition: background-color 0.5s ease;

      svg {
        transform: scale(1);
        transition: transform 0.25s ease;
        height: 1em;
        width: 1em;
        fill: currentColor;
      }
    }

    @media screen and (min-width: ${BREAK_POINTS.Min.MD}) {
      display: block;
      padding: 24px 0;
    }

    &:hover,
    &:focus {
      background-color: ${COLOURS.NEUTRALS.BACKGROUND_DARK};

      svg {
        transform: scale(1.3);
      }
    }
  }
`;
