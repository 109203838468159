import * as React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  overflow: visible;

  &:not(.offscreen) {
    #eSGhbigXKf13_to {
      animation: eSGhbigXKf13_to__to 2307.7ms linear 1 normal forwards;
    }
    @keyframes eSGhbigXKf13_to__to {
      0% {
        transform: translate(24px, 15.8px);
      }
      6.666667% {
        transform: translate(24px, 15.8px);
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
      }
      30% {
        transform: translate(24px, 7px);
      }
      40% {
        transform: translate(24px, 8.9px);
      }
      100% {
        transform: translate(24px, 8.9px);
      }
    }
    #eSGhbigXKf15_ts {
      animation: eSGhbigXKf15_ts__ts 2307.7ms linear 1 normal forwards;
    }
    @keyframes eSGhbigXKf15_ts__ts {
      0% {
        transform: translate(32px, 24.7px) scale(0.97, 0.56);
      }
      6.666667% {
        transform: translate(32px, 24.7px) scale(0.97, 0.56);
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      30% {
        transform: translate(32px, 24.7px) scale(0.97, 1.7);
      }
      40% {
        transform: translate(32px, 24.7px) scale(0.97, 1.4);
      }
      100% {
        transform: translate(32px, 24.7px) scale(0.97, 1.4);
      }
    }
  }
`;

export const LockAnimated = (props: any) => (
  <StyledSvg
    id="eSGhbigXKf11"
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    {...props}
  >
    <path
      id="eSGhbigXKf12"
      d="M24,35C26.2,35,28,33.2,28,31C28,28.8,26.2,27,24,27C21.8,27,20,28.8,20,31C20,33.2,21.8,35,24,35ZM36,18L34,18L14,18L12,18C9.8,18,8,19.8,8,22L8,40C8,42.2,9.8,44,12,44L36,44C38.2,44,40,42.2,40,40L40,22C40,19.8,38.2,18,36,18ZM36,40L12,40L12,22L36,22L36,40Z"
      transform="matrix(1 0 0 1 0 4)"
      fill="currentColor"
      stroke="none"
      strokeWidth="1"
    />
    <g id="eSGhbigXKf13_to" transform="translate(24,15.8)">
      <path
        id="eSGhbigXKf13"
        d="M10,0.931258C14.353335,0.931258,17.295472,3.022742,18.870311,6.381269C19.400468,7.51189,19.735448,8.702532,19.921007,9.889705L19.985205,10.345752C20.039257,10.779012,20.064033,11.14182,20.070008,11.415341L20.070008,16.666401L16.271479,16.666401L16.271478,11.544573C16.271478,11.364843,16.246009,10.984637,16.166591,10.47653C16.032951,9.62152,15.793374,8.769979,15.429775,7.994562C14.451027,5.907267,12.796708,4.731258,10.016404,4.731187C7.224876,4.755289,5.581855,5.928434,4.619726,7.990928C4.264624,8.752153,4.031254,9.588006,3.901959,10.429616L3.852763,10.788959C3.819403,11.068043,3.804231,11.293957,3.800466,11.449832L3.799548,16.666401L-0.000496,16.666401L-0.000496,11.550565C-0.002064,11.16004,0.035199,10.57398,0.146024,9.852599C0.326524,8.677685,0.654916,7.501493,1.175995,6.384468C2.729551,3.054146,5.650212,0.968743,10,0.931258Z"
        transform="translate(-10.035468,-8.798829)"
        fill="currentColor"
        stroke="none"
        strokeWidth="1"
      />
    </g>
    <path id="eSGhbigXKf14" d="" fill="none" stroke="rgb(63,87,135)" strokeWidth="0.096" />
    <g id="eSGhbigXKf15_ts" transform="translate(32,24.7) scale(0.97,0.56)">
      <rect
        id="eSGhbigXKf15"
        width="3.9"
        height="7.1"
        rx="0"
        ry="0"
        transform="translate(-1.958089,-7.102378)"
        fill="currentColor"
        stroke="none"
        strokeWidth="0"
      />
    </g>
  </StyledSvg>
);
