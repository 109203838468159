import * as React from 'react';

function SvgGas(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <g fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M6.002 13.76L6 13.926a6 6 0 1011.998-.165c-.042-1.903-.65-3.091-1.758-4.195L12 5.19 7.76 9.52c-1.109 1.099-1.716 2.335-1.758 4.24zM17.66 7.92A8.02 8.02 0 0120 13.56c0 2-.78 4.11-2.34 5.67a7.99 7.99 0 01-11.32 0C4.78 17.67 4 15.56 4 13.56s.78-4.08 2.34-5.64L12 2.27l5.66 5.65z"
          fill="currentColor"
        />
        <path
          d="M11.127 14.56c-.836 1.3-.836 3.573 0 4.873.301.47.598.657.824.657.228 0 .523-.186.825-.657.835-1.3.835-3.573 0-4.873l-.825-1.284-.824 1.284zm2.91-.811c1.154 1.794 1.154 4.701 0 6.495-.575.898-1.331 1.346-2.086 1.346-.754 0-1.51-.45-2.086-1.346-1.153-1.794-1.153-4.701 0-6.495L11.95 10.5l2.087 3.249z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgGas;
