import styled from 'styled-components';

import { SkeletonVariant } from 'src/models/content.interfaces';
import { BREAK_POINTS } from '../../../consts/style';
import { getHeightObj } from '../../../utils/utils';

interface ISkeletonRoot {
  variant: SkeletonVariant;
}

export const SkeletonRoot = styled('span')`
  display: block;
  background-color: #f1f1f1;
  height: ${(p: ISkeletonRoot) => getHeightObj('SM', p.variant)};
  animation: 1.5s ease-in-out 0.5s infinite normal none running animation-pulse;
  margin: 10px 0;

  @keyframes animation-pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
  transform-origin: 0 55%;

  ${(props: ISkeletonRoot) =>
    props.variant === 'text' &&
    `
      &:empty::before {
          content: "\\00a0";
      }
  `}

  @media screen and (min-width: ${BREAK_POINTS.Min.LG}) {
    height: ${(p: ISkeletonRoot) => getHeightObj('MD', p.variant)};
    margin: 4px 0;
  }
`;
