import * as React from 'react';

import { IProductQuickLinks } from '../../models/content.interfaces';

import { SectionHeading } from '../Common/Headings';
import { List } from './ProductQuickLinks.styles';
import { ProductQuickLink } from './ProductQuickLink';

export const ProductQuickLinks = ({ items, sectionHeading }: IProductQuickLinks) => {
  return (
    <>
      <SectionHeading heading={sectionHeading} />
      <List data-id="product-quick-links" aria-label="product-quick-links">
        {items && items.map((item) => <ProductQuickLink {...item} key={item.label} />)}
      </List>
    </>
  );
};
