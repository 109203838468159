import { css } from 'styled-components';
import { hexToRGB } from '../utils/utils';

export const COLOURS = {
  BRAND: {
    PRIMARY: '#EC0000',
    PRIMARY_ORANGE: '#FA4616',
    SECONDARY: '#FFB432',
  },
  SEMANTIC_TONES: {
    CRITICAL: '#EC0000',
    CAUTION: '#FFB432',
    INFO: '#0A5FD2',
  },
  NEUTRALS: {
    DARK: '#232323',
    LIGHT: '#737373',
    DEFAULT: '#505050',
    DISABLED: '#D5D5D5',
    BORDERS: '#E3E3E3',
    BACKGROUND_DARK: '#F1F1F1',
    BACKGROUND_LIGHT: '#F8F8F8',
  },
  BACKGROUNDS: {
    ORANGE: '#FA4616',
    WARM_GREY: '#F0EFED',
    YELLOW: '#FFB432',
    AQUA: '#00B9D7',
    RED: '#FF1441',
  },
} as const;

export const BREAK_POINTS = {
  Min: {
    SM: '576px',
    MD: '768px',
    LG: '992px',
    XL: '1200px',
  },
  Max: {
    SM: '575px',
    MD: '767px',
    LG: '991px',
    XL: '1199px',
  },
};

export const OFFERS_THEME_MAP = {
  white: {
    backgroundColour: 'white',
    headingColour: 'orange',
    textColour: 'default',
    ctaColour: 'default',
  },
  orange: {
    backgroundColour: COLOURS.BACKGROUNDS.ORANGE,
    headingColour: 'white',
    textColour: 'dark',
    ctaColour: 'yellow',
  },
  yellow: {
    backgroundColour: COLOURS.BACKGROUNDS.YELLOW,
    headingColour: 'default',
    textColour: 'dark',
    ctaColour: 'default',
  },
  'light-yellow': {
    backgroundColour: hexToRGB(COLOURS.BACKGROUNDS.YELLOW, 16),
    headingColour: 'orange',
    textColour: 'default',
    ctaColour: 'default',
  },
  'light-blue': {
    backgroundColour: hexToRGB(COLOURS.BACKGROUNDS.AQUA, 16),
    headingColour: 'default',
    textColour: 'default',
    ctaColour: 'default',
  },
};

export const handleHeadingColour = (colour?: string) => {
  switch (colour) {
    case 'orange':
      return COLOURS.BRAND.PRIMARY_ORANGE;
    case 'white':
      return 'white';
    default:
      return COLOURS.NEUTRALS.DARK;
  }
};

export const handleFontColour = (colour?: string) => {
  switch (colour) {
    case 'dark':
      return COLOURS.NEUTRALS.DARK;
    case 'light':
      return COLOURS.NEUTRALS.LIGHT;
    case 'orange':
      return COLOURS.BRAND.PRIMARY_ORANGE;
    case 'yellow':
      return COLOURS.BRAND.SECONDARY;
    case 'light-blue':
      return COLOURS.BACKGROUNDS.AQUA;
    case 'white':
      return 'white';
    default:
      return COLOURS.NEUTRALS.DEFAULT;
  }
};

export const TickList = css`
  display: inline-block;
  padding-left: 0;
  margin: 0 0 16px;

  & li {
    margin-bottom: 12px;
  }
  & li:last-child {
    margin-bottom: 0;
  }
`;

export const TickItem = css`
  list-style-type: none;
  padding-left: 32px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"%3E%3Cpath d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"%3E%3C/path%3E%3C/svg%3E');
`;

export const FadeBanner = css`
  animation: fadeIn linear 0.8s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const paddingTop = '40px';

export const FadeUpStart = css`
  0% {
    opacity: 0;
    padding-top: ${paddingTop};
  }
`;

export const FadeUpEnd = css`
  100% {
    opacity: 1;
    padding-top: 0;
  }
`;

export const FadeUp = css`
  animation: fadeUp linear 0.8s;
  @keyframes fadeUp {
    ${FadeUpStart}

    ${FadeUpEnd}
  }
`;

type Duration = '0' | '0.8' | '1.2' | '1.6';
type Pause = '0' | '20' | '40' | '60' | '80';

type FadeUpVariableProps = Readonly<{
  duration: Duration;
  pause: Pause;
}>;

export const FadeUpVariable = ({ duration, pause }: FadeUpVariableProps) => css`
  animation: fadeUp ${pause} linear ${duration}s;
  @keyframes fadeUp${pause} {
    ${FadeUpStart}
    ${pause}% {
      opacity: 0;
      padding-top: ${paddingTop};
    }
    ${FadeUpEnd}
  }
`;
